<template>
  <div class="page-main">
    <h1>{{ $t("connexion.register") }}</h1>

    <div class="topPageMenu">
      <router-link :to="{ name: 'connexion' }">
        {{ $t("cancel") }}
      </router-link>
    </div>

    <p class="middleText">
      {{ $t("connexion.finProvided") }}
      <span class="fin">{{ fin }}</span>
    </p>

    <form id="connection" class="connection" method="POST" @submit="validate">
      <fieldset>
        <Select
          v-model="inputs.timezone"
          :label="$t('timezone.label')"
          :options="$t('timezone.items')"
          :required="true"
          :refeshError="refeshError"
          @error="checkError"
        />
        <br />
        <GenericInput
          v-model="inputs.firstname"
          :label="$t('fields.firstname')"
          :required="true"
          :maxlength="30"
          :refeshError="refeshError"
          type="text"
          @error="checkError"
        />
        <GenericInput
          v-model="inputs.lastname"
          :label="$t('fields.lastname')"
          :required="true"
          :maxlength="30"
          :refeshError="refeshError"
          type="text"
          @error="checkError"
        />
        <GenericInput
          v-model="inputs.address"
          :label="$t('fields.address')"
          :required="true"
          :maxlength="200"
          type="text"
          :refeshError="refeshError"
          autocomplete="street-address"
          @error="checkError"
        />
        <!-- autocomplete city is locality -->
        <!-- autocomplete zip is postal-code -->
        <Select
          v-model="inputs.province"
          :label="$t('province.label')"
          :options="$t('province.items')"
          :required="true"
          :refeshError="refeshError"
          @error="checkError"
        />
        <Select
          v-model="inputs.dfoRegion"
          :label="$t('editTrip.subscriptionStep.dfo')"
          :help="$t('editTrip.subscriptionStep.dfoHelp')"
          :options="$t('dfoRegion.items')"
          :required="true"
          :refeshError="refeshError"
          @error="checkError"
        />
        <br />
        <GenericInput
          v-model="inputs.email"
          :label="$t('fields.email')"
          :required="true"
          :refeshError="refeshError"
          type="email"
          @error="checkError"
        />
        <br />
        <GenericInput
          v-model="inputs.phoneNumber"
          :label="$t('fields.phone')"
          :phoneUS="true"
          :required="true"
          :refeshError="refeshError"
          type="phone"
          autocomplete="new-phone"
          @error="checkError"
        />
      </fieldset>
      <p class="middleText">
        {{ $t("connexion.passwordSafe") }}
      </p>
      <br />
      <fieldset>
        <PasswordInput
          v-model="inputs.newPassword"
          :label="$t('password.new')"
          :placeholder="$t('password.new')"
          :required="true"
          :displayPassword="true"
          :minlength="8"
          :refeshError="refeshError"
          autocomplete="new-password"
          @error="checkError"
          @binding="checkPasswordConfirmation"
        />
        <PasswordInput
          v-model="inputs.newPasswordAgain"
          :label="$t('password.confirm')"
          :placeholder="$t('password.confirm')"
          :required="true"
          :displayPassword="true"
          :forceErrorMsg="passwordConfirmError"
          :refeshError="refeshError"
          autocomplete="new-password"
          @error="checkError"
          @binding="checkPasswordConfirmation"
        />
      </fieldset>
      <p class="middleText">
        {{ $t("connexion.questions") }}
      </p>
      <fieldset>
        <Select
          v-model="inputs.securityQuestion1"
          :label="$t('security.label.question', { num: 1 })"
          :options="systemsLists?.securityQuestions"
          :errorMsg="$t('security.errorMsg')"
          :arrayValidator="[inputs.securityQuestion3, inputs.securityQuestion2]"
          :required="true"
          :refeshError="refeshError"
          @error="checkError"
        />
        <GenericInput
          v-model="inputs.answer1"
          :label="$t('security.label.answer')"
          :maxlength="250"
          :required="true"
          :refeshError="refeshError"
          type="text"
          @error="checkError"
        />
        <br />
        <Select
          v-model="inputs.securityQuestion2"
          :label="$t('security.label.question', { num: 2 })"
          :options="systemsLists?.securityQuestions"
          :errorMsg="$t('security.errorMsg')"
          :arrayValidator="[inputs.securityQuestion1, inputs.securityQuestion3]"
          :required="true"
          :refeshError="refeshError"
          @error="checkError"
        />
        <GenericInput
          v-model="inputs.answer2"
          :label="$t('security.label.answer')"
          :maxlength="250"
          :required="true"
          :refeshError="refeshError"
          type="text"
          @error="checkError"
        />
        <br />
        <Select
          v-model="inputs.securityQuestion3"
          :label="$t('security.label.question', { num: 3 })"
          :options="systemsLists?.securityQuestions"
          :errorMsg="$t('security.errorMsg')"
          :arrayValidator="[inputs.securityQuestion1, inputs.securityQuestion2]"
          :required="true"
          :refeshError="refeshError"
          @error="checkError"
        />
        <GenericInput
          v-model="inputs.answer3"
          :label="$t('security.label.answer')"
          :maxlength="250"
          :required="true"
          :refeshError="refeshError"
          type="text"
          @error="checkError"
        />
      </fieldset>
      <button class="primaryAction" type="submit">
        {{ $t("connexion.login") }}
      </button>
    </form>
  </div>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import Select from "@/components/Select.vue";
import PasswordInput from "@/components/widgets/PasswordInput.vue";
import { mapState, mapActions } from "vuex";
import Jobel from "@/service/jobel";
import DB from "@/service/db";

export default {
  name: "connexionsignupform",
  components: {
    GenericInput,
    PasswordInput,
    Select
  },
  data() {
    return {
      fin: null,
      captcha: null,
      inputs: {
        province: null,
        address: null,
        timezone: null,
        firstname: null,
        lastname: null,
        email: null,
        phoneNumber: null,

        newPassword: null,
        newPasswordAgain: null,

        securityQuestion1: null,
        answer1: null,
        securityQuestion2: null,
        answer2: null,
        securityQuestion3: null,
        answer3: null,
        dfoRegion: null
      },
      refeshError: 0,
      error: {},
      passwordConfirmError: false
    };
  },
  methods: {
    ...mapActions([
      "getSystemsLists",
      "showLoadingOverlay",
      "hideLoadingOverlay"
    ]),
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    checkPasswordConfirmation(e) {
      const hasPassword =
        this.inputs.newPassword && this.inputs.newPassword.length > 0;
      const hasConfirmation =
        this.inputs.newPasswordAgain && this.inputs.newPasswordAgain.length > 0;
      if (
        hasPassword &&
        hasConfirmation &&
        this.inputs.newPassword != this.inputs.newPasswordAgain
      ) {
        this.passwordConfirmError = this.$i18n.t("validator.unconfirmed");
        ++this.refeshError;
      }
    },
    validate: function(e) {
      e.preventDefault();
      const vuejs = this;
      const inputs = vuejs.inputs;
      const fin = vuejs.fin;
      const captcha = vuejs.captcha;
      const password = inputs.newPassword;

      ++this.refeshError;
      vuejs.$nextTick(() => {
        if (vuejs.$hasInputErrorAndScroll()) {
          return;
        }

        vuejs.$store
          .dispatch("showLoadingOverlay")
          .then(() => Jobel.signUp(fin, captcha, password, inputs))
          .then(() =>
            vuejs.$store.dispatch("logIn", {
              username: fin,
              password: password
            })
          )
          .then(() =>
            vuejs.$router.push({ name: "modulesadd" }).catch(error => {
              // ignore because of exception on navigation guard...
            })
          )
          .catch(error => {
            if (
              error.errorType == "user" &&
              error?.errors.hasOwnProperty("captcha_id")
            ) {
              vuejs.$store.dispatch(
                "alert/error",
                vuejs.$i18n.t("message.technical_error")
              );
              vuejs.$router.replace({ name: "connexion" });
            } else {
              vuejs.$alertUserApiError(error);
            }
          })
          .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
      });
    }
  },
  beforeMount() {
    const vm = this;
    DB.getItem("signup").then(function(tokens) {
      vm.fin = tokens.fin;
      vm.captcha = tokens.captcha;
    });
  },
  watch: {
    "inputs.newPassword"(newVal) {
      this.passwordConfirmError = false;
    },
    "inputs.newPasswordAgain"(newVal) {
      this.passwordConfirmError = false;
    }
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists
    })
  },
  mounted() {
    // @TODO: what to do if network failure ?
    this.showLoadingOverlay()
      .then(() => this.getSystemsLists())
      .then(() => this.hideLoadingOverlay);
  }
};
</script>

<style lang="scss" scoped>
.topPageMenu {
  color: $darkColor;
  padding-bottom: 25px;

  a {
    color: $darkColor;
    display: block;
    text-align: center;
    padding: 7px;

    &:hover {
      text-decoration: underline;
    }
  }

  .separator {
    display: none;
  }
}

.middleText {
  .fin {
    font-size: 2.5em;
    display: block;
  }
  & {
    font-size: 1.2em;
    color: black;
    text-align: center;
    padding: 0px 25px;
  }
}
</style>
