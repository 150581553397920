<template>
  <fieldset v-if="hasBaitUsed && baits.length > 1">
    <h4>{{ $t("section.baits") }}</h4>
    <Select
      :label="$t('fields.firstBaitType')"
      v-model="form.firstBaitType"
      :options="baits"
      :required="false"
      @error="checkError"
      :refeshError="refreshError"
    />
  </fieldset>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Select from "@/components/Select.vue";
import { mapping } from "@/utils/FormStateMapping";

export default {
  name: "Baits",
  components: {
    Select
  },
  props: {
    refreshError: Number,
    checkError: Function,
    form: Object
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      // TODO: Local prop is better ;-)
      baits: state => state.editTripSubform[mapping.baits.stateName]
    }),
    hasBaitUsed() {
      return this.getPropertyValue("hasBaitUsed", {
        subforms: this.form.subform
      });
    }
  },
  watch: {
    baits() {
      if (!this.hasBaitUsed || !this.baits || this.baits.length == 0)
        this.form["firstBaitType"] = null;
    }
  }
};
</script>
