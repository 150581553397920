<template>
  <div class="list">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.gearLossStep.title") }}</span>
        </span>
      </span>
    </h3>
    <fieldset
      v-for="(loss, index) in form.gearLossEvents"
      :key="index"
      class="removeBottomPadding"
    >
      <h4 v-if="form.gearLossEvents.length > 0" class="sectionTitle">
        {{ $t("editTrip.gearLossStep.loss") }}&nbsp;{{ index + 1 }}
        <span
          v-b-modal="'suppGearLossEntry-' + index + 1 + '-geotemporal-remark'"
          class="add-remark-btn-title"
          v-show="loss['closeDatetime'] === undefined"
        ></span>
        <Popup
          v-model="loss['remark']"
          :title-label="$t('editTrip.remarkTitle')"
          :maxlength="2000"
          :modalName="'suppGearLossEntry-' + index + 1 + '-geotemporal-remark'"
        />
      </h4>
      <b-overlay
        :show="loss['closeDatetime'] !== undefined"
        rounded="sm"
        blur="none"
        :key="'loss' + index + loss['closeDatetime']"
      >
        <template #overlay>
          <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
          <p class="redMessage">{{ $t("closeMsg") }}</p>
          <p class="redMessage">
            {{ $t("closeMsgDate") }}{{ showDate(loss["closeDatetime"]) }}
          </p>
        </template>
        <fieldset :disabled="loss['closeDatetime'] !== undefined">
          <GenericInput
            type="datetime"
            v-model="loss['creation']"
            :hideTime="true"
            :label="$t('editTrip.gearLossStep.repDate')"
            :required="true"
            :passDate="true"
            :autodatetime="true"
            :refeshError="showErrorData + refreshIndex[index]"
            @error="
              error => {
                addError(error, index);
              }
            "
          />
          <!-- TODO: Remplacer options avec lostGearStatuses dans systemsLists. -->
          <Select
            :label="$t('editTrip.gearLossStep.status')"
            :options="$t('gearStatusOptions.items')"
            v-model="loss['status']"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
          />
          <br v-if="loss['status'] && !isNoGearLostSelected(loss['status'])" />
          <GenericInput
            v-if="loss['status'] && !isNoGearLostSelected(loss['status'])"
            :label="$t('fields.fishingLicence')"
            v-model.trim="loss['fishingLicenceNumber']"
            type="text"
            :required="true"
            :minlength="4"
            :maxlength="$const.licenceMaxLength"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'gearType-' + index + '-fin'"
          />
          <Select
            v-if="loss['status'] && !isNoGearLostSelected(loss['status'])"
            :label="$t('fields.targetSpecie')"
            v-model="loss['target']"
            :options="targetSpecies"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'gearType-' + index + '-target'"
          />
          <Select
            v-if="loss['status'] && isLostSelected(loss['status'])"
            :label="$t('fields.area')"
            v-model="loss['area']"
            :options="fishingAreaOptions"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'gearType-' + index + '-area'"
          />
          <GenericInput
            type="datetime"
            v-if="loss['status'] && !isNoGearLostSelected(loss['status'])"
            v-model="loss.occursAt['datetime']"
            :hideTime="true"
            :label="$t('editTrip.gearLossStep.lostDate')"
            :required="true"
            :passDate="true"
            :autodatetime="true"
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'lostDate-' + index + loss['status']"
            @error="
              error => {
                addError(error, index);
              }
            "
          />
          <GenericInput
            type="datetime"
            v-if="
              loss['status'] &&
                !isNoGearLostSelected(loss['status']) &&
                !isLostSelected(loss['status'])
            "
            v-model="loss['recovery']"
            :hideTime="true"
            :label="$t('editTrip.gearLossStep.recoveryDate')"
            :required="true"
            :passDate="true"
            :autodatetime="true"
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'recoveryDate-' + index + loss['status']"
            @error="
              error => {
                addError(error, index);
              }
            "
          />
          <Select
            v-if="loss['status'] && !isNoGearLostSelected(loss['status'])"
            :label="$t('editTrip.gearLossStep.gearType')"
            :options="lostGearTypes"
            v-model="loss['gearType']"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'gearType2-' + index + loss['status']"
          />
          <br v-if="loss['status'] && !isNoGearLostSelected(loss['status'])" />
          <GeopositionWidget
            v-if="loss['status'] && !isNoGearLostSelected(loss['status'])"
            :position="loss.occursAt"
            @binding="
              function changeValue(value) {
                loss.occursAt = { ...loss.occursAt, ...value };
              }
            "
            :required="true"
            :minLat="[38.0]"
            :maxLat="[72.0]"
            :minLong="[-148.0]"
            :maxLong="[-40.0]"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'latitude-' + index + loss['status']"
          />
          <Select
            v-if="
              isLostSelected(loss['status']) &&
                isDirectionEnabled(loss['gearType'])
            "
            :label="$t('editTrip.gearLossStep.direction')"
            v-model="loss['direction']"
            :options="$t('mvCardinalPoint.items')"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'direction-' + index + loss['status']"
          />
          <br v-if="loss['status'] && !isNoGearLostSelected(loss['status'])" />
          <IntegerInput
            v-if="loss['status'] && !isNoGearLostSelected(loss['status'])"
            :label="$t('editTrip.gearLossStep.nbGear')"
            v-model="loss['nbGear']"
            :required="true"
            :min="1"
            :max="9999"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'nbGear-' + index + loss['status']"
          />
          <br v-if="loss['status'] && !isNoGearLostSelected(loss['status'])" />
          <GenericInput
            v-if="loss['status'] && !isNoGearLostSelected(loss['status'])"
            :label="$t('editTrip.gearLossStep.gearDesc')"
            v-model="loss['gearDesc']"
            type="text"
            :maxlength="150"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'gearDesc-' + index + loss['status']"
          />
          <UnitConverter
            v-if="loss['status'] && !isNoGearLostSelected(loss['status'])"
            :label="$t('editTrip.gearLossStep.ropeLength')"
            v-model="loss['ropeLength']"
            :required="true"
            :min="0"
            unit="m"
            :max="150000"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refresh="showErrorData + refreshIndex[index]"
            :key="'ropeLength-' + index + loss['status']"
          />
          <UnitConverter
            v-if="
              loss['status'] &&
                !isNoGearLostSelected(loss['status']) &&
                [905, 1089, 1091, 1105, 1101, 1105].includes(loss['gearType'])
            "
            :label="$t('editTrip.gearLossStep.netLength')"
            v-model="loss['netLength']"
            unit="m"
            :required="true"
            :min="0"
            :max="99999.999"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refresh="showErrorData + refreshIndex[index]"
            :key="'netLength-' + index + loss['status'] + loss['gearType']"
          />
          <IntegerInput
            v-if="
              loss['status'] &&
                !isNoGearLostSelected(loss['status']) &&
                [905, 914, 925, 1105].includes(loss['gearType'])
            "
            :label="$t('editTrip.gearLossStep.nbBuoys')"
            v-model="loss['nbBuoys']"
            :required="true"
            :min="0"
            :max="1500"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'nbBuoys-' + index + loss['status'] + loss['gearType']"
          />
          <GenericInput
            v-if="
              loss['status'] &&
                !isNoGearLostSelected(loss['status']) &&
                loss['nbBuoys'] > 0 &&
                [905, 914, 925, 1105].includes(loss['gearType'])
            "
            :label="$t('editTrip.gearLossStep.buoyDesc')"
            v-model="loss['buoyDesc']"
            type="text"
            :maxlength="150"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'buoyDesc-' + index + loss['status'] + loss['nbBuoys']"
          />
          <br v-if="loss['status'] && !isNoGearLostSelected(loss['status'])" />
          <GenericInput
            v-if="loss['status'] && !isNoGearLostSelected(loss['status'])"
            :label="$t('editTrip.gearLossStep.tags')"
            :help="$t('editTrip.gearLossStep.tagsHelp')"
            v-model="loss['tags']"
            type="tags"
            :max="loss['nbGear']"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'tags-' + index + loss['nbGear']"
          />
        </fieldset>
      </b-overlay>
      <fieldset class="suppEntryButtonWrapper removeBottomPadding">
        <input
          v-if="!loss['closeDatetime']"
          @click="closeLossEvent(index)"
          class="closeSuppEntryButton"
          type="button"
          :class="
            index + 1 < form.gearLossEvents.length
              ? 'addBottomMargin'
              : 'removeBottomMargin'
          "
          :value="$t('editTrip.gearLossStep.closeGear') + String(index + 1)"
        />
      </fieldset>
    </fieldset>
    <fieldset class="suppEntryButtonWrapper removeTopMargin">
      <div>
        <input
          v-if="
            form.gearLossEvents.length > 1 &&
              !$isLastItemClosed(form.gearLossEvents)
          "
          @click="removeLossEvent()"
          class="removeSuppEntryButton"
          type="button"
          :value="
            $t('editTrip.gearLossStep.removeGear') +
              String(form.gearLossEvents.length)
          "
        />
        <input
          @click="addLossEvent()"
          class="addSuppEntryButton"
          type="button"
          :value="$t('editTrip.gearLossStep.addGear')"
        />
      </div>
    </fieldset>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import GenericInput from "@/components/GenericInput.vue";
import Select from "@/components/Select.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import DateFormat from "@/utils/dateFormat.js";
import { mapping } from "@/utils/FormStateMapping";
import { clearFieldsFromObject } from "@/utils/utils";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  components: {
    GenericInput,
    GeopositionWidget,
    IntegerInput,
    Select,
    Popup,
    UnitConverter
  },
  name: "GearLossStep",
  data: () => ({
    form: {
      gearLossEvents: []
    },
    licenceMaxLength: 7,
    firstTime: true,
    showErrorData: 0,
    name: "gearLossStep",
    refreshIndex: []
  }),
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      lostGearTypes: state => state.systemsLists.lostGearTypes,
      subscription: state => state.currentSubscription,
      targetSpecies: state =>
        state.editTripSubform[mapping.TargetSpecies.stateName],
      currentPage: state => state.editTripSubform.currentPage,
      showError: state => state.editTripSubform.showError,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      subform: state => state.editTripSubform.subform,
      stateErrors: state => state.editTripSubform.errors
    }),
    areas() {
      return this.getPropertyValue(
        "areas",
        {
          subforms: this.subform,
          regions: this.openTrip.dfoRegion,
          targetSpecies: this.subscription.preferences.licences[0].target
        },
        []
      );
    },
    fishingAreaOptions() {
      return [
        { value: null, text: "---------", disabled: false },
        ...this.areas
      ];
    },
    isNoGearLostSelected() {
      return status => {
        return status === 39621;
      };
    },
    isDirectionEnabled() {
      return engin => {
        return engin !== 905 && engin !== 914 && engin !== 925;
      };
    },
    isLostSelected() {
      return status => {
        return status === 39581;
      };
    }
  },
  watch: {
    showError() {
      if (this.isFinalReview || this.currentPage === this.name) {
        ++this.showErrorData;
      }
    },
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    },
    currentPage() {
      if (this.currentPage === this.name) {
        this.autoCreateGearLossEntries();
      }
    },
    isFinalReview() {
      if (this.isFinalReview) {
        this.clearGearLostGhostData();
      }
    }
  },
  methods: {
    ...mapActions(["closeObject", "addErrorsToState"]),
    addLossEvent() {
      this.showErrorData += 1;
      this.$nextTick(() => {
        const errorsFieldsNames = keysMatchingValue(
          this.stateErrors,
          true,
          "gearLossStep"
        );
        if (errorsFieldsNames.length > 0) {
          scrollToTopField(errorsFieldsNames);
          return;
        }
        this.form.gearLossEvents.push(this.emptyGearLostEvent());
        this.refreshIndex.push(0);
      });
    },
    /**
     * TODO : remove gearLossStep-lastIndex from stateErrors
     * for now its simply replacing error: true by error: false
     * but it should completely remove the error object
     */
    removeLossEvent() {
      this.form.gearLossEvents.pop();
      this.refreshIndex.pop();
    },
    async closeLossEvent(index) {
      Vue.set(this.refreshIndex, index, this.refreshIndex[index] + 1);
      this.closeObject({
        value: this.form.gearLossEvents,
        type: `gearLossStep-${index}`,
        index: index,
        vm: this
      });
    },
    addError(error, index) {
      this.addErrorsToState({ page: `gearLossStep-${index}`, errors: error });
      this.addErrorsToState({ page: "gearLossStep", errors: error });
    },
    getDefaultTargetSpecieForGearLoss() {
      let targetSpecie = null;
      if (this.subscription.preferences.licences.length > 0) {
        targetSpecie = this.subscription.preferences.licences[0].target;
      }
      if (
        this.openTrip.efforts?.length > 0 &&
        this.openTrip.efforts[0].target
      ) {
        targetSpecie = this.openTrip.efforts[0].target;
      }
      return targetSpecie;
    },
    getDefaultFishingLicenceForGearLoss() {
      let fishingLicenceNumber = "";
      if (this.subscription.preferences.licences.length > 0) {
        fishingLicenceNumber = this.subscription.preferences.licences[0][
          "fishingLicenceNumber"
        ];
      }
      if (
        this.openTrip.efforts?.length > 0 &&
        this.openTrip.efforts[0].licenceNumber &&
        this.openTrip.efforts[0].licenceNumber.length > 0
      ) {
        fishingLicenceNumber = this.openTrip.efforts[0].licenceNumber;
      }
      return fishingLicenceNumber;
    },
    autoCreateGearLossEntries() {
      if (this.form.gearLossEvents.length > 0) {
        return;
      }
      let lossEvent = {};
      this.openTrip.efforts
        .filter(effort => effort.confirmationGear === this.$const.YES)
        .map(effort => {
          lossEvent = this.confirmedGearLost(effort.gears, effort.start);
          lossEvent.occursAt = this.getEndPosition(
            effort.tows,
            effort.endPosition,
            effort.start
          );
          this.form.gearLossEvents.push(lossEvent);
          this.refreshIndex.push(0);
          this.firstTime = false;
        });
      if (this.form.gearLossEvents.length === 0) {
        this.form.gearLossEvents.push(this.noFishingGearLostEvent());
      }
      this.refreshIndex = Array(this.form.gearLossEvents.length).fill(0);
    },
    gearLossErrorByIndex(index) {
      if (this.refeshErrorByIndex["gearLossStep" + index]) {
        return this.refeshErrorByIndex["gearLossStep" + index];
      } else {
        return 0;
      }
    },
    showDate(d) {
      const date = new Date(d);
      return date.toLocaleString(this.$i18n.locale, {
        timeZone: DateFormat.getZone(this.userProfile)
      });
    },
    emptyGearLostEvent() {
      return {
        remark: null,
        gearType: null,
        nbGear: null,
        ropeLength: null,
        occursAt: {
          longitude: null,
          latitude: null,
          datetime: null
        },
        area: this.getAreaFromPreferences(),
        status: null,
        creation:
          this.form.gearLossEvents.length > 0 ? "" : new Date().toISOString(),
        tags: ""
      };
    },
    noFishingGearLostEvent() {
      const noFishingGearLost = 39621;
      return {
        creation: new Date().toISOString(),
        status: noFishingGearLost,
        occursAt: {},
        area: this.getAreaFromPreferences(),
        fishingLicenceNumber: this.getDefaultFishingLicenceForGearLoss(),
        target: this.getDefaultTargetSpecieForGearLoss()
      };
    },
    confirmedGearLost(gearType, recovery) {
      return {
        creation: new Date().toISOString(),
        gearType,
        occursAt: { datetime: recovery },
        recovery,
        area: this.getAreaFromPreferences(),
        status: null,
        fishingLicenceNumber: this.getDefaultFishingLicenceForGearLoss(),
        target: this.getDefaultTargetSpecieForGearLoss()
      };
    },
    getEndPosition(tows, endPosition, datetime) {
      const lastTow = tows[tows.length - 1];
      const position = lastTow?.endPosition ?? endPosition;
      return {
        longitude: position?.longitude || null,
        latitude: position?.latitude || null,
        autolat: position?.autolat || null,
        autolon: position?.autolon || null,
        datetime
      };
    },
    getAreaFromPreferences() {
      return this.subscription.preferences.licences[0].area;
    },
    clearGearLostGhostData() {
      for (let loss of this.form.gearLossEvents) {
        if (this.isNoGearLostSelected(loss["status"])) {
          clearFieldsFromObject(loss, [
            "fishingLicenceNumber",
            "target",
            "occursAt",
            "recovery",
            "gearType",
            "nbGear",
            "gearDesc",
            "ropeLength",
            "netLength",
            "nbBuoys",
            "buoyDesc",
            "tags"
          ]);
        }
        if (this.isLostSelected(loss["status"])) {
          delete loss["recovery"];
          if (
            this.isGillNetSelected(loss["gearType"]) ||
            this.isLongLineSelected(loss["gearType"]) ||
            this.isPotTrapSelected(loss["gearType"])
          ) {
            delete loss["direction"];
          }
        } else {
          clearFieldsFromObject(loss, ["area", "direction"]);
        }
        if (
          !this.isGillNetSelected(loss["gearType"]) &&
          !this.isLongLineSelected(loss["gearType"]) &&
          !this.isPotTrapSelected(loss["gearType"]) &&
          !this.isOtherSelected(loss["gearType"])
        ) {
          delete loss["nbBuoys"];
        }
        if (
          !this.isGillNetSelected(loss["gearType"]) &&
          !this.isTrawlSelected(loss["gearType"]) &&
          !this.isSeineNetslSelected(loss["gearType"]) &&
          !this.isOtherSelected(loss["gearType"])
        ) {
          delete loss["netLength"];
        }
        if (!loss["nbBuoys"]) {
          delete loss["buoyDesc"];
        }
      }
    },
    isDredgesSelected(engin) {
      return engin === 1101;
    },
    isGillNetSelected(engin) {
      return engin === 905;
    },
    isLongLineSelected(engin) {
      return engin === 914;
    },
    isOtherSelected(engin) {
      return engin === 1105;
    },
    isPotTrapSelected(engin) {
      return engin === 925;
    },
    isSeineNetslSelected(engin) {
      return engin === 1091;
    },
    isTrawlSelected(engin) {
      return engin === 1089;
    },
    isTrollerLineSelected(engin) {
      return engin === 918;
    }
  },
  beforeMount() {
    if (this.openTrip.gearLossEvents?.length > 0) {
      this.form.gearLossEvents = this.openTrip.gearLossEvents;
    }
  }
};
</script>
<style lang="scss" scoped></style>
