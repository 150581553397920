import Vue from "vue";
import i18n from "@/i18n";
import DB from "@/service/db";
import db from "@/service/db";
import router from "@/router";
import DateFormat from "@/utils/dateFormat";
import { createEffort } from "@/utils/utils";
import { mapping } from "@/utils/FormStateMapping";

const getDefaultState = () => {
  return {
    errors: {},
    showError: false,
    currentPage: "",
    triggerUpdateOpenTrip: false,
    isFinalReview: false,
    hailOutCallProviders: [{ value: null, text: "---------" }],
    hailInCallProviders: [{ value: null, text: "---------" }],
    hailInCallOption: 0,
    hailOutCallOption: 0,
    hasPartnership: 0,
    operatorsFin: [],
    targetSpecies: [{ value: null, text: "---------" }],
    fishingAreas: [{ value: null, text: "---------" }], // LF: not used, can clean ?  // BL: Referenced by EndEffort.vue but unused inside !?!
    gearTypeOptions: [{ value: null, text: "---------" }],
    gearSubTypeOptions: [{ value: null, text: "---------" }],
    hasDeparturePort: false,
    hasTransfer: false,
    trapSizeOptions: [],
    refeshErrorByIndex: {},
    allowedDfoRegion: {},
    gridValidationArray: [],
    nafoGridValidationArray: [],
    fishingAreasOptions: [{ value: null, text: "---------" }],
    effortAreas: [],
    gearLossAreas: {
      minLat: [],
      maxLat: [],
      minLong: [],
      maxLong: []
    },
    fishingBaitOptions: [{ value: null, text: "---------" }],
    contextDfoRegion: null,
    DFOregionOptions: [
      { value: null, text: "---------" },
      { value: 1008, text: i18n.t("dfoRegion.centralArctic") },
      { value: 1014, text: i18n.t("dfoRegion.gulf") },
      { value: 1004, text: i18n.t("dfoRegion.maritimes") },
      { value: 1002, text: i18n.t("dfoRegion.newfoundland") },
      { value: 1010, text: i18n.t("dfoRegion.pacific") },
      { value: 1006, text: i18n.t("dfoRegion.quebec") }
    ],
    generalError: {},
    suboperators: [],
    suboperatorsFin: [],
    vessels: [],
    vesselsRN: [],
    subformOptions: [],
    subform: null,
    pageLabel: [
      "subscriptionStep",
      "departureStep",
      "experimentalStep",
      "effortsStep",
      "baitStep",
      "tagsStep",
      "inactivityStep",
      "lastStep"
    ]
  };
};

function getDropdownFormattedValues(getters, key, subform, sysList) {
  let systemLists = getters.SYSTEMLISTS;
  let result = getters.getPropertyValue(
    key,
    {
      subforms: subform
    },
    []
  );
  if (sysList !== null)
    return getters.buildOptions(result, systemLists[sysList]);
  if (Array.isArray(result) && result.length > 0) return result;
  if (typeof result !== "number") return 0;
  return result;
}

function checkOverLapDates(currentTrip, existingTrip, currentDateString, vm) {
  let existingTripArrival;
  if (existingTrip.arrival && existingTrip.arrival.datetime) {
    existingTripArrival = new Date(existingTrip.arrival.datetime);
  } else if (
    existingTrip.arrival.cargoEvents &&
    existingTrip.arrival.cargoEvents[0] &&
    existingTrip.arrival.cargoEvents[0].datetime
  ) {
    existingTripArrival = new Date(
      existingTrip.arrival.cargoEvents[0].datetime
    );
  }

  const existingTripDepartureDate = new Date(existingTrip.departure.datetime);
  const selectedDate = new Date(currentDateString);
  // is the selected date is between a departure AND arrival of an exisiting trip
  if (
    selectedDate >= existingTripDepartureDate &&
    selectedDate <= existingTripArrival
  ) {
    return vm.$t("editTrip.rangeOtherTrip");
  }

  if (!currentTrip.departure?.datetime) {
    return undefined;
  }

  const currentDepartureDate = new Date(currentTrip.departure?.datetime);
  if (
    existingTripDepartureDate > currentDepartureDate &&
    existingTripDepartureDate < selectedDate
  ) {
    return vm.$t("editTrip.rangeOtherTrip");
  }
  if (
    existingTripArrival > currentDepartureDate &&
    existingTripArrival < selectedDate
  ) {
    return vm.$t("editTrip.rangeOtherTrip");
  }

  return undefined;
}

export default {
  namespaced: false,
  state: getDefaultState(),
  actions: {
    triggerShowError({ state }) {
      state.showError = !state.showError;
    },
    changeCurrentPage({ state }, page) {
      state.currentPage = page;
    },
    addErrorsToState({ state }, payload) {
      const page = payload.page ?? "default";
      if (!(page in state.errors)) {
        state.errors[page] = {};
      }
      state.errors[page] = { ...state.errors[page], ...payload.errors };
    },
    cleanErrorsFromState({ state }) {
      state.errors = {};
    },
    updateIsFinalReview({ state }, newValue) {
      state.isFinalReview = newValue;
    },
    performRefreshByIndex({ commit }, index) {
      commit("updateRefeshErrorByIndex", index);
    },
    updateEffortAreas({ commit }, payload) {
      commit("updateEffortAreas", payload);
    },
    updateSubStateGeneric({ commit }, payload) {
      commit("updateSubStateGeneric", payload);
    },
    resetEditTripState({ commit }) {
      console.log("Resetting editTrip state"); // eslint-disable-line no-console
      commit("resetEditTripState");
    },
    emitError({ commit }, error) {
      commit("commitError", error);
    },
    initPageLabel({ commit, getters, state }) {
      let pageLabel = getDefaultState().pageLabel;

      const hasBaitUsed = getters.getPropertyValue("hasBaitUsed", {
        subforms: state.subform
      });

      const hasBaitTowNum = getters.getPropertyValue("hasBaitTowNum ", {
        subforms: state.subform
      });
      //@FIXME: constants are in vue components, not availble here ?
      const BLOCKED = 0;
      const MANDATORY = 2;
      if (
        (hasBaitUsed === BLOCKED || hasBaitTowNum === MANDATORY) &&
        pageLabel.indexOf("baitStep") !== -1
      ) {
        pageLabel.splice(pageLabel.indexOf("baitStep"), 1);
      }

      if (
        !getters.SUBSCRIPTION.hasExperimentalSections &&
        pageLabel.indexOf("experimentalStep") !== -1
      ) {
        pageLabel.splice(pageLabel.indexOf("experimentalStep"), 1);
      }

      const hasTagNum1 = getters.getPropertyValue("hasTagNum1", {
        subforms: state.subform
      });

      const hasTagTowNum = getters.getPropertyValue("hasTagTowNum", {
        subforms: state.subform
      });

      if (
        (hasTagNum1 == 0 || hasTagTowNum == 2) &&
        pageLabel.indexOf("tagsStep") !== -1
      ) {
        pageLabel.splice(pageLabel.indexOf("tagsStep"), 1);
      }

      commit("updateSubStateGeneric", {
        name: "pageLabel",
        value: pageLabel
      });
    },
    addPageLabel({ commit, state }, label) {
      let pageLabel = state.pageLabel;
      let index;
      if (pageLabel.indexOf(label) !== -1) {
        return;
      }

      let firstIndex = 3;

      if (pageLabel.indexOf("baitStep") !== -1) {
        firstIndex++;
      }

      if (pageLabel.indexOf("experimentalStep") !== -1) {
        firstIndex++;
      }

      if (label === "gearLossStep") {
        index = firstIndex;
      } else if (label === "speciesAtRiskStep") {
        if (pageLabel.indexOf("gearLossStep") === -1) {
          index = firstIndex;
        } else {
          index = pageLabel.indexOf("gearLossStep") + 1;
        }
      } else if (label === "mmiStep") {
        if (pageLabel.indexOf("speciesAtRiskStep") === -1) {
          if (pageLabel.indexOf("gearLossStep") === -1) {
            index = firstIndex;
          } else {
            index = pageLabel.indexOf("gearLossStep") + 1;
          }
        } else {
          index = pageLabel.indexOf("speciesAtRiskStep") + 1;
        }
      } else if (label === "inactivityStep") {
        index = pageLabel.length - 1;
      }

      if (index) {
        const newPageLabel = [
          ...pageLabel.slice(0, index),
          label,
          ...pageLabel.slice(index)
        ];
        commit("updateSubStateGeneric", {
          name: "pageLabel",
          value: newPageLabel
        });
      }
    },
    removePageLabel({ commit, state, getters }, label) {
      let pageLabel = state.pageLabel;
      if (pageLabel.indexOf(label.value) === -1) {
        return;
      }

      pageLabel.splice(pageLabel.indexOf(label.value), 1);
      commit("updateSubStateGeneric", {
        name: "pageLabel",
        value: pageLabel
      });
    },
    getValueBasedOnSubformAndArea({ getters }, { key, subform, dataList }) {
      for (let i in dataList) {
        const area = dataList[i].area;
        const areaHasKey = getters.getPropertyValue(key, {
          subforms: subform,
          areas: area
        });
        if (areaHasKey) {
          return true;
        }
      }
      return false;
    },
    async closeObject({ state, dispatch }, { value, type, index, vm }) {
      await vm.$nextTick();
      await vm.$nextTick();
      const errors = type in state.errors ? state.errors[type] : {};
      const fieldsWithErrors = Object.entries(errors)
        .filter(([key, value]) => value === true)
        .map(([key, value]) => key);
      if (fieldsWithErrors.length > 0) {
        vm.$scrollToTopElements(fieldsWithErrors);
        return;
      }

      const clickOK = await vm.$swal({
        title: vm.$t("areYouSure"),
        text: vm.$t("closeForm"),
        icon: "warning",
        buttons: [vm.$t("no"), vm.$t("yes")],
        dangerMode: true
      });
      if (!clickOK) {
        return false;
      }
      const currentDate = new Date().toISOString();
      if (
        Array.isArray(value) &&
        index < value.length &&
        value[index]?.closeDatetime == null
      ) {
        var newData = value[index];
        newData.closeDatetime = currentDate;
        Vue.set(value, index, newData);
      } else if (
        typeof value === "object" &&
        value &&
        value.closeDatetime == null
      ) {
        vm.$set(value, "closeDatetime", currentDate);
      }

      await vm.$nextTick();
      await vm.$nextTick();

      state.triggerUpdateOpenTrip = !state.triggerUpdateOpenTrip;
      return true;
    },
    lastDate({ state, getters }, { departureDateTime, vm, trip }) {
      const currentTrip = trip ?? getters.OPEN_TRIP;
      const departureDate = departureDateTime
        ? new Date(departureDateTime)
        : null;

      let date = {
        value: new Date(0).toString(),
        text: ""
      };
      if (departureDate && new Date(departureDate) > new Date(date.value)) {
        date.value = currentTrip.departure.datetime;
        date.text = vm.$t("editTrip.beforeDepartureDate").toString();
      }

      for (let effort of currentTrip?.efforts) {
        if (effort.start && new Date(effort.start) > new Date(date.value)) {
          date.value = effort.start;
          date.text = vm.$t("editTrip.beforeStartDate").toString();
        }
        if (effort.end && new Date(effort.end) > new Date(date.value)) {
          date.value = effort.end;
          date.text = vm.$t("editTrip.beforeEndDate").toString();
        }
      }

      for (let bait of currentTrip?.baitUsages) {
        if (bait.datetime && new Date(bait.datetime) > new Date(date.value)) {
          date.value = bait.datetime;
          date.text = vm.$t("editTrip.beforeBaitDate").toString();
        }
      }

      for (let sar of currentTrip?.speciesAtRisk) {
        if (
          sar.occursAt &&
          sar.occursAt.datetime &&
          new Date(sar.occursAt.datetime) > new Date(date.value)
        ) {
          date.value = sar.occursAt.datetime;
          date.text = vm.$t("editTrip.beforeSARDate").toString();
        }
      }

      return date;
    },
    async generateTrip({ dispatch, commit, getters, state }, context) {
      commit("SET_IN_EDIT_TRIP", true);

      let form = {
        createdBy: process.env.VUE_APP_VERSION,
        completedBy: null,
        software: {
          provider: null,
          version: null
        },
        form: getters.SUBSCRIPTION.module.form,
        creation: null,
        crew: [],
        crewNb: null,
        logbookUID: null,
        tripNumber: null,
        remark: null,
        sentToDFO: false,
        status: "open",
        closeDatetime: null,
        sortingDatetime: null,
        totalweight: null,
        experimentalData: [],
        dfoRegion: this.contextDfoRegion,
        partnership: null,
        operator: {
          fisherIdentificationNumber: null,
          name: null
        },
        vessel: {
          registeredNumber: null,
          name: null
        },
        carrier: null,
        departure: {
          datetime: null,
          remark: null,
          port: null
        },
        arrival: {
          datetime: null,
          remark: null,
          port: null
        },
        transport: null,
        hailIns: [],
        hailOuts: [],
        gearInfos: [],
        baitUsages: [],
        gearLossEvents: [],
        speciesAtRisk: [],
        interactions: [],
        incidentTypes: null,
        transfers: null,
        personnalUses: [],
        efforts: [],
        tags: [],
        inactivity: {}
      };

      const createTrip =
        !getters.OPEN_TRIP ||
        getters.OPEN_TRIP === null ||
        !getters.OPEN_TRIP["creation"];

      if (createTrip) {
        //@TODO Change date format
        let date = new Date();
        form["creation"] = date.toISOString();

        //@TODO get software info
        form.software["version"] = process.env.VUE_APP_VERSION;
        form.software["provider"] = context.$t("editTrip.providerName");

        let preferences = getters.SUBSCRIPTION.preferences;
        form.operator["fisherIdentificationNumber"] =
          preferences.operator.fisherIdentificationNumber;
        form.operator["name"] = preferences.operator.name;
        form.operator["email"] = preferences.operator.email;
        form.operator["address"] = preferences.operator.address;
        form.operator["phoneNumber"] = preferences.operator.phoneNumber;
        form.vessel["name"] = preferences.vessel.name;
        form.vessel["registeredNumber"] = preferences.vessel.registeredNumber;

        form["dfoRegion"] = preferences.dfoRegion;
        if (preferences.dfoRegion) {
          commit("updateSubStateGeneric", {
            name: "contextDfoRegion",
            value: preferences.dfoRegion
          });
        }

        form["partnership"] = preferences.partnership;
        form["crew"] = preferences.crew;
        form["crewNb"] = preferences.crewNb;
        form.arrival["port"] = preferences.arrivalPort;
        form.departure["port"] = preferences.departurePort;

        let licence = Object.values(preferences.licences)[0];
        let effort = createEffort(preferences, licence);
        const stateNameGears = mapping.GearsTypes.stateName;
        if (stateNameGears && state[stateNameGears].length === 2) {
          effort.gears = state[mapping.GearsTypes.stateName][1].value;
        }

        form.efforts.push(effort);
      } else {
        commit("updateSubStateGeneric", {
          name: "contextDfoRegion",
          value: getters.OPEN_TRIP["dfoRegion"]
        });
        form = { ...form, ...getters.OPEN_TRIP };
        form.status = "open";
      }
      await dispatch("setCurrentOpenTrip", {
        uuid: getters.SUBSCRIPTION.uuid,
        currentOpenTrip: form
      });
      router.push({
        name: "subformedittrip",
        params: { uuid: getters.SUBSCRIPTION.uuid, subform: context.subform }
      });
    },
    generateOptions({ commit, getters }, payload) {
      // Using the mapping object, this loop will update all states
      // using the current context (user provided values)
      const context = {};
      const importantContextKeys = [
        "subforms",
        "regions",
        "targetSpecies",
        "gears",
        "areas"
      ];
      for (let contextKey of importantContextKeys) {
        if (payload.hasOwnProperty(contextKey)) {
          if (payload[contextKey] !== null) {
            context[contextKey] = payload[contextKey];
          } else {
            if (process.env.VUE_APP_DEBUG_SUBFORM) {
              console.debug("generateOptions: ignoring key", contextKey); // eslint-disable-line no-console
            }
          }
        }
      }
      if (process.env.VUE_APP_DEBUG_SUBFORM) {
        console.debug("generateOptions: using context", context); // eslint-disable-line no-console
      }

      for (let key in mapping) {
        let defaultValue = mapping[key]["list"] ? [] : 0;
        let values = getters.getPropertyValue(
          mapping[key]["subscriptionName"],
          context,
          defaultValue
        );
        if (process.env.VUE_APP_DEBUG_SUBFORM) {
          console.debug("generateOptions: got values for key", key, values); // eslint-disable-line no-console
        }

        const systemListKey = mapping[key]["list"];
        if (systemListKey && Array.isArray(values) && values.length > 0) {
          if (!getters.SYSTEMLISTS[systemListKey])
            console.debug("empty sys list", systemListKey, mapping[key]); // eslint-disable-line no-console

          const dropDownData = getters.buildOptions(
            values,
            getters.SYSTEMLISTS[systemListKey]
          );
          commit("updateSubStateGeneric", {
            name: mapping[key]["stateName"],
            value: dropDownData
          });
        } else {
          commit("updateSubStateGeneric", {
            name: mapping[key]["stateName"],
            value: values
          });
        }
      }
    },
    generateSubformOptions({ commit, getters }) {
      const dropDownData = getDropdownFormattedValues(
        getters,
        mapping.Subforms["subscriptionName"],
        null,
        mapping.Subforms["list"]
      );
      commit("updateSubStateGeneric", {
        name: mapping.Subforms.stateName,
        value: dropDownData
      });
    },
    cleanSubformOptions({ commit, getters }) {
      commit("updateSubStateGeneric", {
        name: mapping.Subforms.stateName,
        value: []
      });
    },
    generateVesselData({ commit, getters }) {
      let userProfile = getters.USER_PROFILE;
      const values = Object.values(userProfile.vessels);
      let vessels = [];
      let vesselsRN = [];
      const NO_VESSEL_NUMBER = 999999;
      for (let i = 0; i < values.length; i++) {
        if (values[i].registeredNumber === NO_VESSEL_NUMBER) continue;

        vessels.push({
          text: values[i].name + " #" + values[i].registeredNumber,
          value: values[i].name + "??" + values[i].registeredNumber
        });
        vesselsRN.push({
          text: values[i].name + " #" + values[i].registeredNumber,
          value: values[i].registeredNumber + "??" + values[i].name
        });
      }
      commit("updateSubStateGeneric", {
        name: "vessels",
        value: vessels
      });
      commit("updateSubStateGeneric", {
        name: "vesselsRN",
        value: vesselsRN
      });
    },
    generateOperatorsData({ commit, getters }) {
      //Generate suboperators
      let userProfile = getters.USER_PROFILE;
      let values = Object.values(userProfile.operators);
      let suboperators = [];
      let suboperatorsFin = [];
      for (let i = 0; i < values.length; i++) {
        suboperators.push({
          text: values[i].name + " #" + values[i].fisherIdentificationNumber,
          value: values[i].name + "??" + values[i].fisherIdentificationNumber
        });
        suboperatorsFin.push({
          text: values[i].name + " #" + values[i].fisherIdentificationNumber,
          value: values[i].fisherIdentificationNumber + "??" + values[i].name
        });
      }
      commit("updateSubStateGeneric", {
        name: "suboperators",
        value: suboperators
      });
      commit("updateSubStateGeneric", {
        name: "suboperatorsFin",
        value: suboperatorsFin
      });

      //Generate operatorsFin
      values = getters.SUBSCRIPTION.preferences.licences;
      let operatorsFin = [];
      for (let i = 0; values && i < values.length; i++) {
        operatorsFin.push({
          text: `${values[i].fishingLicenceNumber}`,
          value:
            values[i].fishingLicenceNumber +
            "??" +
            values[i].target +
            "??" +
            values[i].area +
            "??" +
            values[i].fishingType +
            "??" +
            values[i].nbGear +
            "??" +
            values[i].section
        });
      }
      commit("updateSubStateGeneric", {
        name: "operatorsFin",
        value: operatorsFin
      });
    },
    generateSubform({ commit, dispatch, getters, state }, payload) {
      DB.setItem("subform", payload.subform);
      let userProfile = getters.USER_PROFILE;
      let subform = parseInt(payload.subform);
      commit("updateSubStateGeneric", {
        name: "subform",
        value: subform
      });

      // Using the mapping object, this loop will update all states
      // that are affected by changing the subform dropdown
      for (let key in mapping) {
        const dropDownData = getDropdownFormattedValues(
          getters,
          mapping[key]["subscriptionName"],
          subform,
          mapping[key]["list"]
        );
        commit("updateSubStateGeneric", {
          name: mapping[key]["stateName"],
          value: dropDownData
        });
      }
    },
    getSubform({ commit }) {
      db.getItem("subform").then(function(subform) {
        commit("updateSubStateGeneric", {
          name: "subform",
          value: subform
        });
      });
    }
  },
  getters: {
    errorMessageDateOverlap: (state, getters) => (
      vm,
      date,
      isLanding = false
    ) => {
      let selectedTrip = getters.OPEN_TRIP;
      if (isLanding) selectedTrip = getters.SELECTED_TRIP;

      const TRIP_LIST = getters.TRIP_LIST;
      for (let i in TRIP_LIST) {
        const trip = TRIP_LIST[i];
        if (trip.logbookUID !== selectedTrip.logbookUID) {
          const overlapDate = checkOverLapDates(selectedTrip, trip, date, vm);
          if (overlapDate != null) {
            return overlapDate;
          }
        }
      }

      const CLOSED_TRIPS = getters.CLOSED_TRIPS;
      for (let i in CLOSED_TRIPS) {
        const trip = CLOSED_TRIPS[i];
        if (trip.logbookUID !== selectedTrip.logbookUID) {
          const overlapDate = checkOverLapDates(selectedTrip, trip, date, vm);
          if (overlapDate != null) {
            return overlapDate;
          }
        }
      }
      return undefined;
    },
    showDate: (state, getters) => date => {
      const userProfile = getters.USER_PROFILE;
      return new Date(date).toLocaleString(i18n.locale, {
        timeZone: DateFormat.getZone(userProfile)
      });
    },
    getPropertyValue: (state, getters) => (
      property,
      context = {},
      defaultValue = 0
    ) => {
      if ("module" in getters.SUBSCRIPTION) {
        const properties = getters.SUBSCRIPTION.module;
        if (property in properties && Array.isArray(properties[property])) {
          for (const prop of properties[property]) {
            const keys = Object.keys(prop);
            let passRequirement = true;
            for (const key of keys) {
              if (key !== "v") {
                if (!(key in context) || !prop[key].includes(context[key])) {
                  passRequirement = false;
                  break;
                }
              }
            }
            if (passRequirement) {
              return prop.v;
            }
          }
        }
      }
      return defaultValue;
    },
    buildOptions: (state, getters) => (values, items, disabled = true) => {
      let options = [{ value: null, text: "---------", disabled: disabled }];

      if (!items) {
        return options;
      }

      // Create a map of items by their values
      let optionsMap = {};
      items.forEach(item => {
        optionsMap[item.value] = item;
      });

      // Build the list of options using the map
      for (let value of values) {
        let current = optionsMap[value];
        if (current) {
          options.push(current);
        }
      }

      return options;
    }
  },
  mutations: {
    updateRefeshErrorByIndex(state, index) {
      if (state.refeshErrorByIndex[index]) {
        state.refeshErrorByIndex[index]++;
      } else {
        state.refeshErrorByIndex[index] = 1;
      }
      // Idiom to trigger watchers
      state.refeshErrorByIndex = JSON.parse(
        JSON.stringify(state.refeshErrorByIndex)
      );
    },
    updateEffortAreas(state, payload) {
      state.effortAreas[payload.index] = payload.value;
    },
    updateSubStateGeneric(state, payload) {
      state[payload.name] = payload.value;
      // Idiom to trigger watchers
      state[payload.name] = JSON.parse(JSON.stringify(state[payload.name]));
    },
    resetEditTripState(state) {
      Object.assign(state, getDefaultState());
    },
    commitError(state, error) {
      state.generalError = { ...state.generalError, ...error };
      // Idiom to trigger watchers
      state.generalError = JSON.parse(JSON.stringify(state.generalError));
    }
  }
};

function getEffortWeight(trip, licence, quota) {
  const start = new Date(quota.start);
  const end = new Date(quota.end);
  let keptWeight = 0;
  let creation;
  if (trip.sortingDatetime) {
    creation = new Date(trip.sortingDatetime);
  } else if (trip.departure && trip.departure.datetime) {
    creation = new Date(trip.departure.datetime);
  } else if (trip.arrival && trip.arrival.datetime) {
    creation = new Date(trip.arrival.datetime);
  }
  if (creation && creation >= start && creation <= end && trip.efforts) {
    for (const effort of trip.efforts) {
      if (effort && effort.licenceNumber === licence.fishingLicenceNumber) {
        for (const tow of effort["tows"]) {
          for (const catchValue of tow["catches"]) {
            if (
              catchValue.species === effort.target &&
              catchValue["keptWeight"]
            ) {
              keptWeight += catchValue["keptWeight"];
            }
          }
        }
      }
    }
  }
  return keptWeight;
}
