<template>
  <fieldset>
    <h4 class="darkTitle">{{ $t("editTrip.effortStep.catches") }}</h4>
    <div class="catchEntriesContainer">
      <div class="suppCatchEntry">
        <fieldset
          v-for="(c, catchIndex) in catches"
          :key="`catch-{effortIndex}-{towIndex}-` + catchIndex"
        >
          <CatchEdit
            :showErrorData="showErrorData"
            :addError="addError"
            :effort="effort"
            :options="catchOptions"
            :initialCatch="c"
            :species="catchSelectSpecies"
            :exclude="usedSpecies"
            :isTarget="catchIndex === 0"
            @update="data => updateCatch(catchIndex, data)"
            @specie="updateDeclaredSpecie"
          />
        </fieldset>
      </div>
      <p v-if="!autoOpenCatch">
        <input
          v-if="catches.length > forcedCatchQuantity"
          @click="removeCatch()"
          class="removeSuppEntryButton"
          type="button"
          :value="$t('editTrip.effortStep.removeCatches')"
        />
        <input
          @click="addCatch()"
          class="addSuppEntryButton"
          type="button"
          :value="$t('editTrip.effortStep.addCatches')"
        />
      </p>
    </div>
    <div v-if="bycatchSpecies.length > 0">
      <h4>{{ $t("editTrip.effortStep.bycatches") }}</h4>
      <fieldset class="catchEntriesContainer">
        <div class="suppCatchEntry">
          <fieldset
            v-for="(bycatch, bycatchIndex) in bycatches"
            :key="bycatchIndex"
            class="bycatch"
          >
            <BycatchEdit
              :showErrorData="showErrorData"
              :addError="addError"
              :effort="effort"
              :options="catchOptions"
              :initialCatch="bycatch"
              :species="bycatchSelectSpecies"
              :exclude="usedSpecies"
              @update="data => updateBycatch(bycatchIndex, data)"
              @specie="updateDeclaredSpecie"
            />
          </fieldset>
        </div>
        <div>
          <input
            v-if="bycatches.length > 0"
            @click="removeBycatch()"
            class="removeSuppEntryButton"
            type="button"
            :value="$t('editTrip.effortStep.removeBycatches')"
          />
          <input
            @click="addBycatch()"
            class="addSuppEntryButton"
            type="button"
            :value="$t('editTrip.effortStep.addBycatches')"
          />
        </div>
      </fieldset>
    </div>
  </fieldset>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import CatchEdit from "@/components/subformEditTrip/EffortStepComponents/Tow/CatchEdit.vue";
import BycatchEdit from "@/components/subformEditTrip/EffortStepComponents/Tow/BycatchEdit.vue";

export default {
  name: "CatchManager",
  components: {
    CatchEdit,
    BycatchEdit
  },
  props: {
    showErrorData: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    addError: Function,
    effortIndex: Number,
    towIndex: Number,
    showModal: Boolean,
    effort: Object,
    tow: Object
  },
  data() {
    return {
      autoOpenCatch: false,
      catchOptions: {},
      optionalSpecies: [],
      catchSelectSpecies: [],
      bycatchSpecies: [],
      bycatchSelectSpecies: [],
      usedSpecies: [],
      catches: [],
      bycatches: [],
      isFirstTime: true,
      forcedCatchQuantity: 1,
      context: {
        forms: null,
        subforms: null,
        regions: null,
        targetSpecies: null,
        gears: null,
        areas: null
      }
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      openTrip: state => state.currentOpenTrip,
      subform: state => state.editTripSubform.subform
    })
  },
  watch: {
    "effort.target": function() {
      if (this.effort.target !== this.context.targetSpecies) {
        if (process.env.VUE_APP_DEBUG_CATCH) {
          // eslint-disable-next-line no-console
          console.info(
            "CatchManager: new target",
            this.effort.target,
            this.context
          );
        }
        this.context.targetSpecies = this.effort.target;
      }
    },
    "effort.gears": function() {
      if (this.effort.gears !== this.context.gears) {
        this.context.gears = this.effort.gears;
      }
    },
    "effort.area": function() {
      if (this.effort.area !== this.context.areas) {
        this.context.areas = this.effort.area;
      }
    },
    context: {
      handler: function() {
        if (process.env.VUE_APP_DEBUG_CATCH) {
          // eslint-disable-next-line no-console
          console.info("CatchManager: context update", this.context);
        }
        this.init();
      },
      deep: true
    },
    catches: {
      handler: function() {
        if (this.isFirstTime) {
          this.isFirstTime = false;
          return;
        }
        if (process.env.VUE_APP_DEBUG_CATCH) {
          // eslint-disable-next-line no-console
          console.debug("CatchManager: emit update-catches");
        }
        this.$emit("update-catches", this.catches);
      },
      deep: true
    }
  },
  methods: {
    init() {
      const vue = this;
      vue.buildCatchOptions();

      if (vue.optionalSpecies.length < 5) {
        this.autoOpenCatch = true;
      }

      // if we have too many potential optional catches, we will use a selector
      if (this.autoOpenCatch) {
        // add all optional catches to the interface and initialize from data
        vue.optionalSpecies.forEach(specie => {
          if (!vue.catches.find(c => c.species === specie)) {
            const origin = vue.tow.catches.find(o => o.species === specie);
            let target = vue.defaultCatch(specie);
            if (origin) {
              Object.assign(target, origin);
            }
            vue.catches.push(target);
          }
        });
      } else {
        // add only catches from trip data; if any
        vue.optionalSpecies.forEach(specie => {
          const origin = vue.tow.catches.find(o => o.species === specie);
          if (!vue.catches.find(c => c.species === specie)) {
            if (origin) {
              let target = vue.defaultCatch(specie);
              Object.assign(target, origin);
              vue.catches.push(target);
            }
          }
        });
      }

      // check that mandatory catches are initialized, they need to be at the top of the list after target
      const mandatories = Object.values(vue.catchOptions).filter(
        option => option.hasMandatory && option.species !== vue.effort.target
      );
      this.forcedCatchQuantity = 1 + mandatories.length;
      mandatories.forEach(option => {
        if (!vue.catches.find(c => c.species === option.species)) {
          const origin = vue.tow.catches.find(
            o => o.species === option.species
          );
          let target = vue.defaultCatch(option.species);
          if (origin) {
            Object.assign(target, origin);
          }
          vue.catches.unshift(target);
        }
      });

      // first catch is the target specie since it is mandatory (unavoidable) and most likely specie caught
      if (!this.catches.find(c => c.species === this.effort.target)) {
        const origin = this.tow.catches.find(
          o => o.species === this.effort.target
        );
        let target = this.defaultCatch(this.effort.target);
        if (origin) {
          Object.assign(target, origin);
        }
        this.catches.unshift(target);
      }
    },
    defaultCatch(specie = null) {
      return {
        species: specie,
        remark: null,
        keptNbSpecimen: null,
        keptWeight: null,
        discNbSpecimen: null,
        discWeight: null,
        berried: null,
        productForm: null
      };
    },
    getCatchPropertiesForSpecie(specie) {
      const properties = [
        "hasCatchKeptWeight",
        "hasDiscWeight",
        "hasCatchSpecimenKept",
        "hasCatchSpecimenDisc",
        "hasBerried",
        "hasCatchSpecimenCaught",
        "hasSpecimenDiscInfected",
        "hasCatchMarketIndicator",
        "hasCatchLegalityIndicator",
        "hasCatchSpecimenHooked",
        "hasCatchTagNum",
        "hasAverageSpecimenWeight",
        "hasDressedLength",
        "hasFlankLength",
        "hasCatchPosition",
        "hasSpecimenKept2",
        "hasSpecimenCondition",
        "hasWeightAndSpecimenCombo",
        "hasSpecieFrmId",
        "needSpecieFrmIfKept",
        "hasCatchBabyDiscWt",
        "hasCatchOtherSpeciesDescription",
        "hasCatchMarketDescription",
        "hasCatchEncounterType",
        "hasCatchSpecieSize",
        "hasCatchCaughtWeight",
        "hasCatchRoeIndicator",
        "hasCatchRoeStage",
        "hasCatchSampleIndicator",
        "hasCatchFemalePercentage",
        "isSpecieOther",
        "catchDetailMode"
      ];

      const determinant = [
        "hasCatchSpecimenKept",
        "hasCatchKeptWeight",
        "hasBerried",
        "hasCatchSpecimenDisc"
      ];

      var catchContext = Object.assign({}, this.context);
      catchContext["catchSpecies"] = specie;

      const vue = this;

      let values = {
        hasMandatory: specie === this.effort.target,
        species: specie
      };
      properties.forEach(function(property) {
        values[property] = vue.getPropertyValue(
          property,
          catchContext,
          vue.$const.BLOCKED
        );
        if (
          determinant.includes(property) &&
          values[property] == vue.$const.MANDATORY
        ) {
          values.hasMandatory = true;
        }
      });

      return values;
    },
    buildCatchOptions() {
      let possibleSpecies = this.getPropertyValue(
        "catchSpecies",
        this.context,
        []
      );

      // adding target specie if missing, always first catch required
      if (!possibleSpecies.includes(this.effort.target)) {
        possibleSpecies.push(this.effort.target);
      }

      // the rest of possibleSpecies are 'accidental' catches, some can be kept others need to be rejected (bycatch)
      const optionals = [];
      const bycatchs = [];
      possibleSpecies.forEach(specie => {
        const properties = this.getCatchPropertiesForSpecie(specie);
        this.catchOptions[specie] = properties;

        if (properties.hasMandatory) {
          // some specie requires a declaration even if not targeted
          // no need to keep track, will have a catch initialized
          return;
        } else if (
          properties.hasCatchKeptWeight != this.$const.BLOCKED ||
          properties.hasCatchSpecimenCaught != this.$const.BLOCKED ||
          properties.hasCatchSpecimenKept != this.$const.BLOCKED
        ) {
          // species that the fisherman can keep even if not targeted, not mandatory
          optionals.push(specie);
        } else {
          bycatchs.push(specie);
        }
      });

      this.optionalSpecies = optionals;
      this.bycatchSpecies = bycatchs;

      this.catchSelectSpecies = [
        { value: null, text: "---------", disabled: true }
      ];
      optionals.forEach(specie => {
        const result = this.systemsLists.species.find(x => x.value === specie);
        if (result) {
          this.catchSelectSpecies.push(result);
        }
      });

      this.bycatchSelectSpecies = [
        { value: null, text: "---------", disabled: true }
      ];
      bycatchs.forEach(specie => {
        const result = this.systemsLists.species.find(x => x.value === specie);
        if (result) {
          this.bycatchSelectSpecies.push(result);
        }
      });

      if (process.env.VUE_APP_DEBUG_CATCH) {
        // eslint-disable-next-line no-console
        console.debug(
          "built catches options",
          this.catchOptions,
          this.optionalSpecies,
          this.bycatchSpecies
        );
      }
    },
    updateDeclaredSpecie(specie) {
      if (specie > 0) {
        this.usedSpecies.push(specie);
      } else {
        const index = this.usedSpecies.indexOf(0 - specie);
        if (index >= 0) {
          this.usedSpecies.splice(index, 1);
        }
      }
      if (process.env.VUE_APP_DEBUG_CATCH) {
        // eslint-disable-next-line no-console
        console.debug("CatchManager: used species updated", this.usedSpecies);
      }
    },
    updateCatch(index, data) {
      if (process.env.VUE_APP_DEBUG_CATCH) {
        // eslint-disable-next-line no-console
        console.debug("CatchManager: updateCatch", index, data);
      }
      this.catches[index] = data;
      this.$emit("update-catches", this.catches);
    },
    removeCatch() {
      this.catches.pop();
    },
    addCatch() {
      this.catches.push(this.defaultCatch());
    },
    removeBycatch() {
      this.bycatches.pop();
    },
    addBycatch() {
      this.bycatches.push(this.defaultCatch());
    },
    updateBycatch(index, bycatch) {
      if (process.env.VUE_APP_DEBUG_CATCH) {
        // eslint-disable-next-line no-console
        console.debug("CatchManager: updateBycatch", index, bycatch);
      }
      this.bycatches[index] = bycatch;
      this.$emit("update-bycatches", this.bycatches);
    }
  },
  beforeMount() {
    if (process.env.VUE_APP_DEBUG_CATCH) {
      // eslint-disable-next-line no-console
      console.info("CatchManager: before mount", this.tow);
    }

    this.context.forms = this.openTrip.form;
    this.context.subforms = this.openTrip.subform;
    this.context.regions = this.openTrip.dfoRegion;
    this.context.targetSpecies = this.effort.target;
    this.context.gears = this.effort.gears;
    this.context.areas = this.effort.area;

    // we need nextTick because we want the context watcher to have built options
    // before we add catches and bycatches from the origin data
    this.$nextTick(() => {
      if (this.tow.catches) {
        this.tow.catches.forEach(origin => {
          // some catch may have been initialized already
          const target = this.catches.find(x => x.species === origin.species);
          if (!target) {
            let target = this.defaultCatch(origin.species);
            Object.assign(target, origin);
            this.catches.push(target);
          }
        });
      }
      if (this.tow.bycatches) {
        this.tow.bycatches.forEach(origin => {
          let target = this.defaultCatch(origin.species);
          Object.assign(target, origin);
          this.bycatches.push(target);
        });
      }
    });
  }
};
</script>
