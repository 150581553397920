var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasPersonalUse)?_c('div',{staticClass:"list addTopMargin"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("editTrip.lastStep.personalUse"))+" ")]),_vm._l((_vm.personalUses),function(personal,index){return _c('fieldset',{key:'personalUse-' + index,staticClass:"removeBottomPadding"},[_c('h4',{staticClass:"listItemTitle startContext sectionTitle"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.lastStep.usage"))+" "+_vm._s(index + 1)+" "),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('suppPersonalUseEntry-' + index + '-personalUse-remark'),expression:"'suppPersonalUseEntry-' + index + '-personalUse-remark'"},{name:"show",rawName:"v-show",value:(personal.closeDatetime === undefined),expression:"personal.closeDatetime === undefined"}],staticClass:"add-remark-btn-title"}),_c('Popup',{attrs:{"title-label":_vm.$t('editTrip.remarkTitle'),"maxlength":2000,"modalName":'suppPersonalUseEntry-' + index + '-personalUse-remark'},model:{value:(personal.remark),callback:function ($$v) {_vm.$set(personal, "remark", $$v)},expression:"personal.remark"}})],1),_c('b-overlay',{key:'personal' + index + personal.closeDatetime,attrs:{"show":personal.closeDatetime !== undefined,"rounded":"sm","blur":"none"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-icon',{attrs:{"icon":"file-lock","variant":"danger","scale":"2"}}),_c('p',{staticClass:"redMessage"},[_vm._v(_vm._s(_vm.$t("closeMsg")))]),_c('p',{staticClass:"redMessage"},[_vm._v(" "+_vm._s(_vm.$t("closeMsgDate"))+_vm._s(_vm.showDate(personal.closeDatetime))+" ")])]},proxy:true}],null,true)},[_c('fieldset',{attrs:{"disabled":personal.closeDatetime !== undefined}},[(personal.species == null)?_c('Select',{attrs:{"label":_vm.$t('editTrip.lastStep.specie'),"options":_vm.catchSpecies,"required":true,"refeshError":_vm.showErrorData + _vm.refreshByIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(personal.species),callback:function ($$v) {_vm.$set(personal, "species", $$v)},expression:"personal.species"}}):_vm._e(),(personal.productForm && personal.species)?_c('h4',{staticClass:"landingDetailTitle"},[_vm._v(" "+_vm._s(_vm.title(personal.species, personal.productForm))+" ")]):_vm._e(),(_vm.hasPersonalUseWeight)?_c('UnitConverter',{attrs:{"label":_vm.$t('editTrip.lastStep.weight'),"required":_vm.hasPersonalUseWeight === _vm.$const.MANDATORY,"min":0,"max":_vm.speciesMaxWeight(personal),"unit":"weight","force-error-msg":_vm.errorMessageWeight(personal.species),"refresh":_vm.showErrorData + _vm.refreshByIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(personal.weight),callback:function ($$v) {_vm.$set(personal, "weight", $$v)},expression:"personal.weight"}}):_vm._e(),(_vm.hasPersonalUseId)?_c('Select',{attrs:{"label":_vm.$t('editTrip.lastStep.usage'),"options":_vm.usageOptions,"required":_vm.hasPersonalUseId === _vm.$const.MANDATORY,"refeshError":_vm.showErrorData + _vm.refreshByIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(personal.usage),callback:function ($$v) {_vm.$set(personal, "usage", $$v)},expression:"personal.usage"}}):_vm._e(),(_vm.hasPersonalUseSize)?_c('Select',{attrs:{"label":_vm.$t('editTrip.lastStep.size'),"options":_vm.trapSizesOptionsBySpecie(personal.species),"required":_vm.hasPersonalUseSize === _vm.$const.MANDATORY,"refeshError":_vm.showErrorData + _vm.refreshByIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(personal.size),callback:function ($$v) {_vm.$set(personal, "size", $$v)},expression:"personal.size"}}):_vm._e(),(_vm.hasPersonalUseNbSpecimens)?_c('IntegerInput',{attrs:{"label":_vm.$t('catch.keptNbSpecimen'),"min":0,"max":_vm.speciesMaxQuantity(personal),"required":_vm.hasPersonalUseNbSpecimens === _vm.$const.MANDATORY,"force-error-msg":_vm.errorMessageQuantity(personal.species),"refeshError":_vm.showErrorData + _vm.refreshByIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(personal.kept),callback:function ($$v) {_vm.$set(personal, "kept", $$v)},expression:"personal.kept"}}):_vm._e()],1),_c('fieldset',{staticClass:"suppEntryButtonWrapper removeBottomPadding"},[_c('p',[(!personal.closeDatetime)?_c('input',{staticClass:"closeSuppEntryButton",class:index + 1 < _vm.personalUses.length
                ? 'addBottomMargin'
                : 'removeBottomMargin',attrs:{"type":"button","value":_vm.$t('editTrip.lastStep.closeUsage') + String(index + 1)},on:{"click":function($event){return _vm.closeUsage(index)}}}):_vm._e()])])])],1)}),_c('fieldset',{staticClass:"suppEntryButtonWrapper removeTopMargin"},[_c('p',[(_vm.personalUses.length > 0 && !_vm.checkCloseDatePersonnal)?_c('input',{staticClass:"removeSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.lastStep.removeUsage') + String(_vm.personalUses.length)},on:{"click":_vm.removeUsage}}):_vm._e(),_c('input',{staticClass:"addSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.lastStep.addUsage')},on:{"click":_vm.addUsage}})])])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }