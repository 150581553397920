<template>
  <fieldset name="EndEffort">
    <h4>
      {{ $t("editTrip.effortStep.endEffort") }}
    </h4>
    <GenericInput
      v-if="hasEffortSonar"
      v-model="effort.sonarEnd"
      :label="$t('editTrip.effortStep.sonarEnd')"
      :required="hasEffortSonar === $const.MANDATORY"
      :passDate="true"
      :autodatetime="true"
      :refeshError="showErrorData"
      :minDates="[
        {
          value: effort.sonarStart,
          text: $t('editTrip.effortStep.beforeSonarStart')
        }
      ]"
      type="datetime"
      @error="addErrorToParent"
    />
    <br v-if="hasEffortSonar" />
    <GenericInput
      v-model="effort.end"
      :label="$t('editTrip.effortStep.end')"
      :required="true"
      :passDate="true"
      :autodatetime="true"
      :forceErrorMsg="lastTripDateAndEffort(effort.end, index)"
      :refeshError="showErrorData"
      :minDates="[
        {
          value: effort.sonarEnd,
          text: $t('editTrip.effortStep.beforeSonarEnd')
        },
        {
          value: effort.sonarStart,
          text: $t('editTrip.effortStep.beforeSonarStart')
        },
        {
          value: effort.start,
          text: $t('editTrip.beforeStartDate')
        },
        {
          value: openTrip.departure.datetime,
          text: $t('editTrip.beforeDepartureDate')
        }
      ]"
      type="datetime"
      @error="addErrorToParent"
    />
    <GeopositionWidget
      v-if="isGeopositionWidgetVisible"
      :disablePos="!hasPosition"
      :position="effort.endPosition"
      :hasNAFO="hasNafo"
      :hasGrid="hasGrid"
      :hasLobsterGrid="hasLobsterGrid"
      :lgrid="effort.lgrid"
      :area="effort.area"
      :required="true"
      :minLat="[area.latMin ?? geoLimits.minLat]"
      :maxLat="[area.latMax ?? geoLimits.maxLat]"
      :minLong="[area.lonMin ?? geoLimits.minLon]"
      :maxLong="[area.lonMax ?? geoLimits.maxLon]"
      :gridValidationArray="gridValidationArray"
      :nafoGridValidationArray="nafoGridValidationArray"
      :refeshError="showErrorData"
      @error="addErrorToParent"
      @binding="
        value => {
          effort.endPosition = value;
        }
      "
    />
    <br />
    <IntegerInput
      v-if="hasPlnUsdDur"
      v-model="effort.spotterPlaneDuration"
      :label="$t('editTrip.effortStep.spotterPlaneDuration')"
      :min="0"
      :max="9999"
      :required="hasPlnUsdDur === this.$const.MANDATORY"
      :refeshError="showErrorData"
      @error="addErrorToParent"
    />
    <GenericInput
      v-if="hasEffortZonesSearch"
      v-model="effort.zonesSearch"
      :maxlength="50"
      :label="$t('editTrip.effortStep.zonesSearch')"
      :help="$t('editTrip.effortStep.zonesSearchHelp')"
      :required="hasEffortZonesSearch === this.$const.MANDATORY"
      :refeshError="showErrorData"
      type="text"
      @error="addErrorToParent"
    />
    <b-col v-if="hasNumberDaysFish" lg="4" sm="6" cols="12">
      <IntegerInput
        :label="$t('editTrip.effortStep.nbFishingDays')"
        v-model="effort['fishingDays']"
        :required="hasNumberDaysFish === $const.MANDATORY"
        :min="1"
        :max="999"
        @error="addErrorToParent"
        :refeshError="showErrorData"
      />
    </b-col>
    <GenericInput
      v-model="effort['confirmationSar']"
      :label="$t('editTrip.effortStep.hadSAR')"
      :required="true"
      :options="systemsLists.response"
      :refeshError="showErrorData"
      type="radio"
      @error="addErrorToParent"
    />
    <div v-if="effort['confirmationSar'] === $const.YES">
      {{ $t("editTrip.effortStep.sarMessage") }}
    </div>
    <br />
    <GenericInput
      v-model="effort['confirmationMmi']"
      :label="$t('editTrip.effortStep.hadMammal')"
      :required="true"
      :options="systemsLists.response"
      :refeshError="showErrorData"
      type="radio"
      @error="addErrorToParent"
    />
    <div v-if="effort['confirmationMmi'] === $const.YES">
      {{ $t("editTrip.effortStep.mmiMessage") }}
    </div>
    <br />
    <GenericInput
      v-model="effort['confirmationGear']"
      :label="$t('editTrip.effortStep.hadGearLoss')"
      :required="true"
      :options="systemsLists.response"
      :refeshError="showErrorData"
      type="radio"
      @error="addErrorToParent"
    />
    <div v-if="effort['confirmationGear'] === $const.YES">
      {{ $t("editTrip.effortStep.gearMessage") }}
    </div>
    <br />
    <GenericInput
      v-model="effort['confirmationInactivity']"
      :label="$t('editTrip.effortStep.hadInactivity')"
      :required="true"
      :options="systemsLists.response"
      :refeshError="showErrorData"
      type="radio"
      @error="addErrorToParent"
    />
    <div v-if="effort['confirmationInactivity'] === $const.YES">
      {{ $t("editTrip.effortStep.inactivityMessage") }}
    </div>
  </fieldset>
</template>
<script>
import GenericInput from "@/components/GenericInput.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "EndEffort",
  components: {
    GenericInput,
    GeopositionWidget,
    IntegerInput
  },
  props: {
    addErrorToParent: Function,
    showErrorData: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    effort: Object,
    form: Object,
    index: Number
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      subform: state => state.currentOpenTrip.subform,
      systemsLists: state => state.systemsLists,
      closedTrips: state => state.closedTrips,
      tripList: state => state.currentTripList,
      effortAreas: state => state.editTripSubform.effortAreas,
      gridValidationArray: state => state.editTripSubform.gridValidationArray,
      nafoGridValidationArray: state =>
        state.editTripSubform.nafoGridValidationArray,
      geoLimits: state => state.currentSubscription.module.geoLimits
    }),
    hasNumberDaysFish() {
      return this.getPropertyValue("hasNumberDaysFish", {
        subforms: this.subform
      });
    },
    hasEffortZonesSearch() {
      return this.getPropertyValue("hasEffortZonesSearch", {
        subforms: this.subform
      });
    },
    hasPlnUsdDur() {
      return this.getPropertyValue("hasPlnUsdDur", {
        subforms: this.subform
      });
    },
    hasPositionInEffort() {
      return this.getPropertyValue("hasPositionInEffort", {
        subforms: this.subform
      });
    },
    hasEffortSonar() {
      return this.getPropertyValue("hasEffortSonar", {
        subforms: this.subform
      });
    },
    hasEffortDetailPosition() {
      return this.getPropertyValue("hasEffortDetailPosition", {
        subforms: this.subform,
        areas: this.effort.area
      });
    },
    hasEffortDetailEndPosition() {
      return this.getPropertyValue("hasEffortDetailEndPosition", {
        subforms: this.subform,
        targetSpecies: this.effort.target
      });
    },
    hasPosition() {
      return this.hasEffortDetailEndPosition || this.hasEffortDetailPosition;
    },
    hasEffortDetailGrid() {
      return this.getPropertyValue("hasEffortDetailGrid", {
        subforms: this.subform,
        areas: this.effort.area
      });
    },
    hasEffortDetailEndGrid() {
      return this.getPropertyValue("hasEffortDetailEndGrid", {
        subforms: this.subform,
        targetSpecies: this.effort.target,
        areas: this.effort.area
      });
    },
    hasGrid() {
      return this.hasEffortDetailGrid || this.hasEffortDetailEndGrid;
    },
    hasEffortDetailNafo() {
      return this.getPropertyValue("hasEffortDetailNafo", {
        subforms: this.subform
      });
    },
    hasEffortDetailEndNafo() {
      return this.getPropertyValue("hasEffortDetailEndNafo", {
        subforms: this.subform
      });
    },
    hasNafo() {
      return this.hasEffortDetailNafo || this.hasEffortDetailEndNafo;
    },
    hasStatSection() {
      return this.getPropertyValue("hasStatSection", {
        subforms: this.subform,
        areas: this.effort.area
      });
    },
    hasLobsterGrid() {
      return this.getPropertyValue("hasLobsterGrid", {
        subforms: this.subform,
        areas: this.effort.area
      });
    },
    // Determines whether the GeopositionWidget for the endPosition is visible
    isGeopositionWidgetVisible() {
      return (
        this.hasPositionInEffort &&
        (this.hasPosition ||
          this.hasLobsterGrid ||
          this.hasGrid ||
          this.hasNafo)
      );
    },
    area() {
      return this.effortAreas[this.index] ?? {};
    }
  },
  watch: {
    "effort.confirmationSar": function() {
      this.updatePageLabel();
    },
    "effort.confirmationMmi": function() {
      this.updatePageLabel();
    },
    "effort.confirmationGear": function() {
      this.updatePageLabel();
    },
    "effort.confirmationInactivity": function() {
      this.updatePageLabel();
    },
    isGeopositionWidgetVisible(visible) {
      if (!visible) {
        this.effort["endPosition"] = null;
      }
    }
  },
  methods: {
    ...mapActions(["addPageLabel", "removePageLabel", "setCurrentOpenTrip"]),
    updatePageLabel() {
      this.openTrip.efforts[this.index] = this.effort;

      if (this.effort.confirmationSar === this.$const.YES) {
        this.addPageLabel("speciesAtRiskStep");
      } else if (this.isPageUnused("confirmationSar")) {
        this.removePageLabel({ value: "speciesAtRiskStep" });
      }

      if (this.effort.confirmationMmi === this.$const.YES) {
        this.addPageLabel("mmiStep");
      } else if (this.isPageUnused("confirmationMmi")) {
        this.removePageLabel({ value: "mmiStep" });
      }

      if (this.effort.confirmationGear === this.$const.YES) {
        this.addPageLabel("gearLossStep");
      } else if (this.isPageUnused("confirmationGear")) {
        this.removePageLabel({ value: "gearLossStep" });
      }

      if (this.effort.confirmationInactivity === this.$const.YES) {
        this.addPageLabel("inactivityStep");
      } else if (this.isPageUnused("confirmationInactivity")) {
        this.removePageLabel({ value: "inactivityStep" });
        delete this.openTrip.inactivity;
      }

      this.setCurrentOpenTrip({
        uuid: this.$route.params.uuid,
        currentOpenTrip: this.openTrip
      });
    },
    isPageUnused(confirmation) {
      return !this.openTrip.efforts.some(
        effort => effort[confirmation] === this.$const.YES
      );
    },
    lastTripDateAndEffort(date, effortIndex) {
      for (let i in this.form.efforts) {
        const effort = this.form.efforts[i];
        const value = new Date(date);
        if (effortIndex.toString() !== i.toString()) {
          if (
            effort.start &&
            value >= new Date(effort.start) &&
            effort.end &&
            value <= new Date(effort.end)
          ) {
            return this.$t("editTrip.rangeOtherEffort");
          }
        }
      }
      return this.lastTripDate(date);
    },
    lastTripDate(date) {
      let rv;
      for (let i in this.tripList) {
        const trip = this.tripList[i];
        rv = this.checkOverlapDate(trip, date);
        if (rv) {
          return rv;
        }
      }
      for (let i in this.closedTrips) {
        const trip = this.closedTrips[i];
        rv = this.checkOverlapDate(trip, date);
        if (rv) {
          return rv;
        }
      }

      return rv;
    },
    checkOverlapDate(trip, date) {
      const currentDate = new Date(date);
      const departure = new Date(trip.departure.datetime);
      let arrival;
      if (trip.arrival && trip.arrival.datetime) {
        arrival = new Date(trip.arrival.datetime);
      } else if (
        trip.arrival.cargoEvents &&
        trip.arrival.cargoEvents[0] &&
        trip.arrival.cargoEvents[0].datetime
      ) {
        arrival = new Date(trip.arrival.cargoEvents[0].datetime);
      }
      if (currentDate >= departure && currentDate <= arrival) {
        return this.$t("editTrip.rangeOtherTrip");
      }

      if (this.openTrip.departure?.datetime != null) {
        const formDeparture = new Date(this.openTrip.departure.datetime);
        if (departure > formDeparture && departure < currentDate) {
          return this.$t("editTrip.rangeOtherTrip");
        }
        if (arrival > formDeparture && arrival < currentDate) {
          return this.$t("editTrip.rangeOtherTrip");
        }
      }
      return undefined;
    }
  },
  beforeMount() {
    this.updatePageLabel();
  }
};
</script>
