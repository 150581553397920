var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('h3',{staticClass:"step-title"},[_c('span',[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.speciesAtRiskStep.title1")))])])])]),_vm._l((_vm.form.speciesAtRisk),function(sar,index){return _c('fieldset',{key:index,staticClass:"removeBottomPadding"},[_c('h4',{staticClass:"sectionTitle"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.speciesAtRiskStep.sarInteraction"))+" "+_vm._s(index + 1)+" "),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('suppSpeciesAtRiskEntry-' + index + '-remark'),expression:"'suppSpeciesAtRiskEntry-' + index + '-remark'"},{name:"show",rawName:"v-show",value:(sar['closeDatetime'] === undefined),expression:"sar['closeDatetime'] === undefined"}],staticClass:"add-remark-btn-title"}),_c('Popup',{attrs:{"title-label":_vm.$t('editTrip.remarkTitle'),"maxlength":2000,"modalName":'suppSpeciesAtRiskEntry-' + index + '-remark'},model:{value:(sar['remark']),callback:function ($$v) {_vm.$set(sar, 'remark', $$v)},expression:"sar['remark']"}})],1),_c('b-overlay',{key:'specie' + index + sar['closeDatetime'],attrs:{"show":sar['closeDatetime'] !== undefined,"rounded":"sm","blur":"none"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-icon',{attrs:{"icon":"file-lock","variant":"danger","scale":"2"}}),_c('p',{staticClass:"redMessage"},[_vm._v(_vm._s(_vm.$t("closeMsg")))]),_c('p',{staticClass:"redMessage"},[_vm._v(" "+_vm._s(_vm.$t("closeMsgDate"))+_vm._s(_vm.showDate(sar["closeDatetime"]))+" ")])]},proxy:true}],null,true)},[_c('fieldset',{attrs:{"disabled":sar['closeDatetime'] !== undefined}},[_c('Select',{attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.species'),"options":_vm.sarOptions,"required":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(sar['species']),callback:function ($$v) {_vm.$set(sar, 'species', $$v)},expression:"sar['species']"}}),_c('br'),_c('GenericInput',{key:'sarDate-' + index,attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.datetime'),"type":"datetime","required":true,"autodatetime":true,"minDates":[
            {
              value: _vm.openTrip.departure['datetime'],
              text: _vm.$t('editTrip.beforeDepartureDate')
            }
          ],"passDate":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(sar.occursAt.datetime),callback:function ($$v) {_vm.$set(sar.occursAt, "datetime", $$v)},expression:"sar.occursAt.datetime"}}),_c('GeopositionWidget',{key:'sarGPS-' + index,attrs:{"position":sar['occursAt'],"required":true,"minLat":[_vm.minSarLat ?? _vm.subscription.module.sarMinLat],"maxLat":[_vm.maxSarLat ?? _vm.subscription.module.sarMaxLat],"minLong":[_vm.minSarLon ?? _vm.subscription.module.sarMinLon],"maxLong":[_vm.maxSarLon ?? _vm.subscription.module.sarMaxLon],"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"binding":changeValue => {
              sar['occursAt'] = { ...sar['occursAt'], ...changeValue };
            },"error":error => {
              _vm.addError(error, index);
            }}}),_c('br'),_c('Select',{key:'sarCondition-' + index,attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.specimenCondition'),"options":_vm.systemsLists.sarCondition,"required":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(sar['specimenCondition']),callback:function ($$v) {_vm.$set(sar, 'specimenCondition', $$v)},expression:"sar['specimenCondition']"}}),_c('br'),_c('IntegerInput',{key:'sarNbSpecimen-' + index,attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.nbSpecimen'),"min":1,"max":9999,"required":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(sar['nbSpecimen']),callback:function ($$v) {_vm.$set(sar, 'nbSpecimen', $$v)},expression:"sar['nbSpecimen']"}}),_c('UnitConverter',{key:'sarWeight-' + index,attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.weight'),"min":0,"unit":"weight","refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(sar['weight']),callback:function ($$v) {_vm.$set(sar, 'weight', $$v)},expression:"sar['weight']"}})],1)]),_c('fieldset',{staticClass:"suppEntryButtonWrapper removeBottomPadding"},[_c('p',[(!sar['closeDatetime'])?_c('input',{staticClass:"closeSuppEntryButton",class:index + 1 < _vm.form.speciesAtRisk.length
              ? 'addBottomMargin'
              : 'removeBottomMargin',attrs:{"type":"button","value":_vm.$t('editTrip.speciesAtRiskStep.close') + String(index + 1)},on:{"click":function($event){return _vm.closeSpeciesEvent(index)}}}):_vm._e()])])],1)}),_c('fieldset',{staticClass:"suppEntryButtonWrapper removeTopMargin"},[_c('p',[(
          _vm.form.speciesAtRisk.length > 0 &&
            !_vm.$isLastItemClosed(_vm.form.speciesAtRisk)
        )?_c('input',{staticClass:"removeSuppEntryButton",attrs:{"disabled":_vm.form.speciesAtRisk.length === 1,"type":"button","value":_vm.$t('editTrip.speciesAtRiskStep.remove') +
            String(_vm.form.speciesAtRisk.length)},on:{"click":function($event){return _vm.removeSpeciesEvent()}}}):_vm._e(),_c('input',{staticClass:"addSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.speciesAtRiskStep.add')},on:{"click":function($event){return _vm.addSpeciesEvent()}}})])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }