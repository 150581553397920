<template>
  <div v-if="hasBaitUsed" class="list">
    <fieldset
      v-for="(bait, index) in baitUsages"
      :key="'baitEntry' + index"
      class="removeBottomPadding"
    >
      <h4 class="listItemTitle sectionTitle">
        {{ $t("editTrip.baitStep.bait") }}&nbsp;{{ index + 1 }}
        <span
          v-b-modal="'suppBaitEntry-' + index + '-baitUsage-remark'"
          v-show="bait['closeDatetime'] === undefined"
          class="add-remark-btn-title"
        ></span>
        <Popup
          v-model="bait['remark']"
          :title-label="$t('editTrip.remarkTitle')"
          :maxlength="2000"
          :modalName="'suppBaitEntry-' + index + '-baitUsage-remark'"
        />
      </h4>
      <b-overlay
        :show="bait['closeDatetime'] !== undefined"
        rounded="sm"
        blur="none"
      >
        <template #overlay>
          <b-row>
            <b-col cols="1" class="overlayTextPosition">
              <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
            </b-col>
            <b-col>
              <p class="redMessage">{{ $t("closeMsg") }}</p>
              <br />
              <p class="redMessage">
                {{ $t("closeMsgDate") }}{{ showDate(bait["closeDatetime"]) }}
              </p>
            </b-col>
          </b-row>
        </template>
        <fieldset :disabled="bait['closeDatetime'] !== undefined">
          <GenericInput
            v-if="hasBaitDatetime"
            :label="$t('editTrip.baitStep.datetime')"
            v-model="bait['datetime']"
            type="datetime"
            :minDates="[
              {
                value: openTrip.departure['datetime'],
                text: $t('editTrip.beforeDepartureDate')
              }
            ]"
            :passDate="true"
            :required="true"
            :hideTime="true"
            :autodatetime="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :forceErrorMsg="errorMessageDateOverlap(this, bait['datetime'])"
          />
          <Select
            :label="$t('editTrip.baitStep.type')"
            v-model="bait['type']"
            :options="baits"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
          />
          <UnitConverter
            v-if="hasAnyBaitWeight"
            :label="$t('editTrip.baitStep.weight')"
            v-model="bait['weight']"
            :required="hasAnyBaitWeight === $const.MANDATORY"
            :min="1"
            :max="999999"
            unit="weight"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refresh="showErrorData + refreshIndex[index]"
          />
          <UnitConverter
            v-if="hasBaitAverageWeight"
            :label="$t('editTrip.baitStep.averageWeightPerGear')"
            v-model="bait['averageWeightPerGear']"
            :required="hasBaitAverageWeight === $const.MANDATORY"
            :min="0"
            :max="999999.999"
            unit="weight"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refresh="showErrorData + refreshIndex[index]"
          />
          <GenericInput
            v-if="conditionRequired(index)"
            :label="$t('editTrip.baitStep.condition')"
            v-model="bait['condition']"
            :options="baitConditionOptions"
            :required="conditionRequired(index) === $const.MANDATORY"
            type="select"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
          />
          <GenericInput
            :label="$t('editTrip.baitStep.origin')"
            v-model="bait['origin']"
            :options="$t('baitsOrigin.items')"
            :required="true"
            type="radio"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
          />
        </fieldset>
      </b-overlay>
      <input
        v-if="!bait['closeDatetime']"
        @click="closeBait(index)"
        class="closeSuppEntryButton"
        type="button"
        :class="
          index + 1 < baitUsages.length
            ? 'addBottomMargin'
            : 'removeBottomMargin'
        "
        :value="$t('editTrip.baitStep.closeBait') + String(index + 1)"
      />
    </fieldset>
    <div class="suppEntryButtonWrapper" style="display: block;">
      <div>
        <input
          v-if="baitUsages.length > 0 && !$isLastItemClosed(this.baitUsages)"
          @click="removeBait"
          class="removeSuppEntryButton"
          type="button"
          :value="
            $t('editTrip.baitStep.removeBait') + String(baitUsages.length)
          "
        />
        <input
          @click="addBait"
          class="addSuppEntryButton"
          type="button"
          :value="$t('editTrip.baitStep.addBait')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import DateFormat from "@/utils/dateFormat.js";
import Select from "@/components/Select.vue";
import { mapping } from "@/utils/FormStateMapping";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

const defaultBait = function(type) {
  return {
    type: type,
    remark: null,
    datetime: null,
    weight: null,
    condition: null,
    origin: null
  };
};

export default {
  components: {
    Select,
    GenericInput,
    Popup,
    UnitConverter
  },
  props: {
    showErrorData: Number,
    addError: Function,
    baitUsages: Array
  },
  data: () => ({
    refreshIndex: [],
    baitConditionOptions: []
  }),
  computed: {
    ...mapGetters(["getPropertyValue", "errorMessageDateOverlap"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      openTrip: state => state.currentOpenTrip,
      subscription: state => state.currentSubscription,
      baits: state => state.editTripSubform[mapping.baits.stateName],
      subform: state => state.editTripSubform.subform,
      userProfile: state => state.userProfile,
      showError: state => state.editTripSubform.showError,
      stateErrors: state => state.editTripSubform.errors,
      currentPage: state => state.editTripSubform.currentPage
    }),
    hasBaitDatetime() {
      return this.getPropertyValue("hasBaitDatetime", {
        subforms: this.subform
      });
    },
    hasAnyBaitWeight() {
      if (
        this.hasBaitOnboardedWeight === this.$const.MANDATORY ||
        this.hasBaitWeight === this.$const.MANDATORY
      ) {
        return this.$const.MANDATORY;
      } else if (
        this.hasBaitOnboardedWeight === this.$const.OPTIONAL ||
        this.hasBaitWeight === this.$const.OPTIONAL
      )
        return this.$const.OPTIONAL;
      return this.$const.BLOCKED;
    },
    hasBaitOnboardedWeight() {
      return this.getPropertyValue("hasBaitOnboardedWeight", {
        subforms: this.subform
      });
    },
    hasBaitWeight() {
      return this.getPropertyValue("hasBaitWeight", {
        subforms: this.subform
      });
    },
    hasBaitAverageWeight() {
      return this.getPropertyValue("hasBaitAverageWeight", {
        subforms: this.subform
      });
    },
    hasBaitUsed() {
      return this.getPropertyValue("hasBaitUsed", {
        subforms: this.subform
      });
    }
  },
  watch: {
    baitUsages() {
      if (this.refreshIndex?.length === 0 && this.baitUsages?.length > 0)
        this.refreshIndex = Array(this.baitUsages.length).fill(0);
    }
  },
  methods: {
    ...mapActions(["addErrorsToState", "closeObject"]),
    closeBait(index) {
      const vm = this;
      vm.$set(this.refreshIndex, index, this.refreshIndex[index] + 1);
      vm.closeObject({
        value: vm.baitUsages,
        type: `baitStep-${index}`,
        index: index,
        vm: vm
      });
    },
    async addBait() {
      await this.$nextTick();

      const errorsFieldsNames = keysMatchingValue(
        this.stateErrors,
        true,
        `baitStep-${this.baitUsages.length - 1}`
      );
      if (errorsFieldsNames.length > 0) {
        scrollToTopField(errorsFieldsNames);
        return;
      }

      this.baitUsages.push(defaultBait());
      this.refreshIndex.push(0);
    },
    removeBait() {
      this.baitUsages.pop();
      this.refreshIndex.pop();
    },
    showDate(d) {
      const date = new Date(d);
      return date.toLocaleString(this.$i18n.locale, {
        timeZone: DateFormat.getZone(this.userProfile)
      });
    },
    // TODO: Should receive bait (this.baitUsages[index]) as parameter
    conditionRequired(index) {
      const value = this.getPropertyValue("hasBaitCondition", {
        baits: this.baitUsages[index].type,
        subforms: this.subform
      });
      if (value === this.$const.BLOCKED) {
        // TODO: Should delete condition instead
        this.baitUsages[index].condition = null;
      }
      return value;
    },
    autoCreateBaitUsed() {
      if (this.baitUsages.length > 0) {
        return;
      }
      const firstBaitType = this.subscription.preferences?.firstBaitType; // can be null or undefined
      if (firstBaitType > 0) {
        this.baitUsages.push(defaultBait(firstBaitType));
      } else {
        this.baitUsages.push(defaultBait()); // requested
      }
    }
  },
  beforeMount() {
    this.baitConditionOptions = [
      { value: null, text: "---------", disabled: true },
      ...this.systemsLists.baitCondition
    ];
  },
  mounted() {
    if (this.hasBaitUsed) {
      // hasBaitUsed in case you mount this component without checking it first!
      this.autoCreateBaitUsed();
    }
    if (this.baitUsages.length > 0)
      this.refreshIndex = Array(this.baitUsages.length).fill(0);
  }
};
</script>
