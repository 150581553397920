<template>
  <fieldset v-if="hasTransfer">
    <h4>{{ $t("editTrip.lastStep.transfers") }}</h4>
    <GenericInput
      v-model="form.hasTransfer"
      :label="$t('fields.hasTransfer')"
      :help="$t('fields.hasTransferHelp')"
      :options="responses"
      :required="true"
      :refeshError="refreshError"
      type="radio"
      @error="checkError"
    />
    <br />
    <GenericInput
      v-if="form.hasTransfer === $const.YES"
      v-model="form.carrier.name"
      :label="$t('carrier.name')"
      :maxlength="50"
      :refeshError="refreshError"
      :disabled="form.carrier.registeredNumber == null"
      type="text"
      @error="checkError"
    />
    <GenericInput
      v-if="form.hasTransfer === $const.YES"
      v-model="form.carrier.registeredNumber"
      :label="$t('carrier.number')"
      :digits="true"
      :minlength="4"
      :min="1000"
      :maxlength="$const.VRN_MAX_LENGTH"
      :refeshError="refreshError"
      type="text"
      @error="checkError"
      @input="
        value => {
          if (value === null) {
            form.carrier.name = null;
          }
        }
      "
    />
  </fieldset>
</template>
<script>
import GenericInput from "@/components/GenericInput.vue";
import { mapping } from "@/utils/FormStateMapping";
import { mapState } from "vuex";

export default {
  components: {
    GenericInput
  },
  props: {
    refreshError: Number,
    checkError: Function,
    form: Object
  },
  computed: {
    ...mapState({
      responses: state => state.systemsLists.response,
      hasTransfer: state => state.editTripSubform[mapping.hasTransfer.stateName]
    })
  },
  watch: {
    hasTransfer() {
      if (!this.hasTransfer) {
        this.form.hasTransfer = null;
        this.form.carrier.registeredNumber = null;
        this.form.carrier.name = null;
      }
    },
    "form.hasTransfer": function(newHasTransfer) {
      if (newHasTransfer !== this.$const.YES) {
        this.form.carrier.registeredNumber = null;
        this.form.carrier.name = null;
      }
    }
  }
};
</script>
