<template>
  <fieldset :disabled="mminteration['closeDatetime'] !== undefined">
    <GenericInput
      v-if="mminteration['hasMmi'] === $const.YES"
      :label="$t('editTrip.speciesAtRiskStep.otherid')"
      v-model="mminteration['otherId']"
      type="text"
      :maxlength="30"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'otherid-' + index"
    />
    <br />
    <Select
      v-if="mminteration['hasMmi'] === $const.YES"
      :label="$t('editTrip.speciesAtRiskStep.species')"
      :options="speciesOptions"
      v-model="mminteration['species']"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
    />
    <br />
    <GenericInput
      :label="$t('editTrip.speciesAtRiskStep.creation')"
      v-model="mminteration['creation']"
      type="datetime"
      :hideTime="true"
      :required="true"
      :autodatetime="true"
      :passDate="true"
      :minDates="[
        {
          value: '2020-01-01T00:00:00.000Z',
          text: $t('editTrip.speciesAtRiskStep.jan12020')
        }
      ]"
      :key="'mmicreation-' + index"
      @error="addErrorToParent"
      :refeshError="parentShowError"
    />
    <GenericInput
      v-if="mminteration['hasMmi'] === $const.YES"
      :label="$t('editTrip.speciesAtRiskStep.datetime')"
      v-model="mminteration['occursAt']['datetime']"
      type="datetime"
      :required="true"
      :autodatetime="true"
      :minDates="[
        {
          value: '2020-01-01T00:00:00.000Z',
          text: $t('editTrip.speciesAtRiskStep.jan12020')
        }
      ]"
      :maxDates="[
        {
          value: minimalDate,
          text: $t('editTrip.speciesAtRiskStep.dateCreationError')
        }
      ]"
      :passDate="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
    />
  </fieldset>
</template>

<script>
import { mapActions, mapState } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import Select from "@/components/Select.vue";

export default {
  components: {
    GenericInput,
    Select
  },
  props: {
    addErrorToParent: Function,
    parentShowError: Number,
    mminteration: Object,
    index: Number
  },
  data() {
    return {
      name: "BasicInfo",
      speciesOptions: []
    };
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists
    }),
    minimalDate() {
      let dateObject = new Date(this.mminteration["creation"]);
      dateObject.setHours(23);
      dateObject.setMinutes(59);
      dateObject.setSeconds(59);
      dateObject.setMilliseconds(999);
      return dateObject;
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"])
  },
  beforeMount() {
    const mmiSpecies = this.systemsLists.mmiSpecies;
    this.speciesOptions = [{ value: null, text: "---------" }];
    for (let index = 0; index < mmiSpecies.length; index++) {
      const element = mmiSpecies[index];
      if (element.text.indexOf("------") < 0) {
        this.speciesOptions.push({
          value: element.value,
          text: element.text
        });
      }
    }
  }
};
</script>

<style></style>
